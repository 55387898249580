<template>
  <div class="VideoPlayer">
    <div class="card" @click="showPlayer = true">
      <img :src="videoThumbnail" alt="Video Thumbnail" />
      <button class="play-button text-size-16">
        <svg width="60.000000" height="60.000000" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <desc>
            Created with Pixso.
          </desc>
          <defs />
          <path id="path"
            d="M30 0C13.43 0 0 13.43 0 30C0 46.56 13.43 60 30 60C46.56 60 60 46.56 60 30C60 13.43 46.56 0 30 0ZM42.56 31.99L25.51 43.24C25.33 43.36 25.14 43.45 24.94 43.51C24.73 43.58 24.52 43.62 24.31 43.63C24.09 43.64 23.88 43.62 23.67 43.57C23.46 43.52 23.26 43.45 23.07 43.35C22.88 43.24 22.71 43.12 22.55 42.97C22.39 42.82 22.26 42.65 22.15 42.47C22.04 42.28 21.96 42.09 21.9 41.88C21.84 41.67 21.81 41.46 21.81 41.25L21.81 18.75C21.81 18.53 21.84 18.32 21.9 18.11C21.96 17.9 22.04 17.71 22.15 17.52C22.26 17.34 22.39 17.17 22.55 17.02C22.71 16.87 22.88 16.75 23.07 16.65C23.26 16.54 23.46 16.47 23.67 16.42C23.88 16.37 24.09 16.35 24.31 16.36C24.52 16.37 24.73 16.41 24.94 16.48C25.14 16.54 25.33 16.64 25.51 16.76L42.56 28.01C42.64 28.06 42.72 28.12 42.79 28.18C42.87 28.25 42.94 28.31 43.01 28.39C43.07 28.46 43.13 28.54 43.19 28.62C43.25 28.7 43.3 28.78 43.35 28.87C43.39 28.95 43.43 29.04 43.47 29.13C43.5 29.23 43.54 29.32 43.56 29.41C43.58 29.51 43.6 29.61 43.61 29.7C43.62 29.8 43.63 29.9 43.63 30C43.63 30.1 43.62 30.19 43.61 30.29C43.6 30.39 43.58 30.49 43.56 30.58C43.54 30.68 43.5 30.77 43.47 30.86C43.43 30.95 43.39 31.04 43.35 31.13C43.3 31.21 43.25 31.3 43.19 31.38C43.13 31.46 43.07 31.54 43.01 31.61C42.94 31.68 42.87 31.75 42.79 31.81C42.72 31.88 42.64 31.93 42.56 31.99Z"
            fill="#FFFFFF" fill-opacity="0.800000" fill-rule="nonzero" />
        </svg>

      </button>
    </div>
    <transition name="fade">
      <div v-if="showPlayer" class="player-overlay" @click="handleOverlayClick">
        <div class="player">
          <video controls autoplay style="height: fit-content;align-self: center;">
            <source :src="videoSource" type="video/mp4" />
            您的浏览器不支持 video 标签。
          </video>
          <!-- <button class="close-button text-size-20" @click="showPlayer = false">关闭</button> -->
        </div>
      </div>
    </transition>
  </div>
</template>




<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

// 定义状态
const showPlayer = ref(false);
const videoThumbnail = require('@/assets/images/官网开发素材/车规产品/background.jpg'); // 替换为实际的视频缩略图 URL
const videoSource = require('@/assets/images/官网开发素材/车规产品/惯导视频4有角标AE2中文版_压缩.mp4'); // 替换为实际的视频文件 URL

// 定义方法
const togglePlayer = () => {
  showPlayer.value = !showPlayer.value;
};


// 添加点击遮罩层关闭的处理函数
const handleOverlayClick = (event) => {
  console.log(event)
  // 检查点击的是否是遮罩层本身（而不是视频播放器）
  
    showPlayer.value = false;
  
};


const handleEsc = (event) => {
  if (event.key === 'Escape') {
    showPlayer.value = false
  }
}

onMounted(() => {
  // 组件挂载时添加事件监听
  document.addEventListener('keydown', handleEsc)
})

onUnmounted(() => {
  // 组件卸载时移除事件监听，防止内存泄漏
  document.removeEventListener('keydown', handleEsc)
})




</script>





<style scoped>
.VideoPlayer {
  position: relative;
  width: 100%;
  height: 100%;
  
}

.card {
  display: flex;
  position: relative;
  width: 100%;          
  height: 100%;
  border-radius: var(--cx-radius);
  overflow: hidden;
  cursor: pointer;
}

/* 添加遮罩层 */
.card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: none; /* 确保遮罩不会影响点击事件 */
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;

}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  padding: -10px -10px;
  border-radius: 1000px;
  width: fit-content;
  cursor: pointer;
  z-index: 20;
}

.player-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.player {
  position: relative;
  width: 80%;
  height: 80%;
  display: flex;

}

.close-button {
  position: absolute;
  text-align: right;
  right: 10px;
  background-color: transparent;
  color: white;
  border: none;

  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.player video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* 或者使用 cover 根据你的需求 */
}
</style>
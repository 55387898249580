<template>
    <div class="SolutionsCard" 
         :style="containerStyle"
         ref="cardRef">
        <p class="title">{{ str1 }}</p>
        <p class="subtitle">{{ str2 }}</p>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

const props = defineProps({
    src: {
        type: String,
        required: true,
    },
    str1: {
        type: String,
        required: true,
    },
    str2: {
        type: String,
        required: true,
    }
});

const isDarkBackground = ref(true);
const cardRef = ref(null);

// 计算容器样式
const containerStyle = computed(() => ({
    backgroundImage: `url(${props.src})`,
    borderRadius: `${props.radius}px`,
}));

// 计算文字样式
const textStyle = computed(() => ({
    color: isDarkBackground.value ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)',
    textAlign: 'center',
}));

// 分析背景图片亮度的函数
const analyzeBackgroundBrightness = async () => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    
    img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        
        ctx.drawImage(img, 0, 0);
        
        try {
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;
            
            let brightness = 0;
            let pixelCount = 0;
            
            // 采样计算平均亮度（每10个像素采样一次以提高性能）
            for (let i = 0; i < data.length; i += 40) {
                const r = data[i];
                const g = data[i + 1];
                const b = data[i + 2];
                
                // 使用 YIQ 公式计算亮度
                brightness += (r * 299 + g * 587 + b * 114) / 1000;
                pixelCount++;
            }
            
            const averageBrightness = brightness / pixelCount;
            isDarkBackground.value = averageBrightness < 128;
            
        } catch (error) {
            console.error('Error analyzing image:', error);
        }
    };
    
    img.onerror = () => {
        console.error('Error loading image');
        isDarkBackground.value = true; // 错误时默认使用白色文字
    };
    
    img.src = props.src;
};

onMounted(() => {
    analyzeBackgroundBrightness();
});
</script>

<style scoped>
.SolutionsCard {
    
    position: relative;
    height: 100%;
    width: 100%;
    padding: 30px;
    background-size: cover;
    background-position: center;
    border: 1px solid #ccc;
    /* box-shadow: 0px 5px 27px 2px rgba(50, 50, 93, 0.05); */    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: var(--cx-radius);
    overflow: hidden;
    
}

/* 添加遮罩 */
.SolutionsCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* 黑色半透明遮罩 */
  z-index: 1;  /* 确保遮罩在背景图上，在内容下 */
}


.title {
    font-size: var(--font-size-24);
    color: rgb(255, 255, 255);
    font-weight: bold;
    z-index: 2;
    text-align: center;
}

.subtitle {
    font-size: var(--font-size-12);
    color: rgb(255, 255, 255);
    z-index: 2;
    text-align: center;
}
</style>
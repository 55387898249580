<template>



    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

                <p class="text-size-64" style="color: rgb(0, 0, 0); text-align: left;">
                    {{ $t('GNM11.gnssModule') }}
                </p>

                <h1 class="text-size-96" style="color: rgb(0, 0, 0); font-weight: bold; text-wrap: nowrap; text-align: left;">
                    GNM11
                </h1>


                <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1); text-align: left;">
                    {{ $t('GNM11.moduleDescription') }}
                </p>


            </div>






        </div>

    </div>


    <div v-show="isMobile" class="h-c">
        <div class="div-spacer-h288"></div>
        <div class="v-c">

            <p class="text-size-16" style="color: rgba(89, 89, 89,1); text-align: left;">
                {{ $t('GNM11.moduleDescription') }}
            </p>
</div>
        <div class="div-spacer-h288"></div>
    </div>    <div v-show="!isMobile" class="div-spacer-h288"></div>





    <div class="h-c">

        <CombinedTitle title="GNM11" shortTitle="" subTitle="GNM11" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/模组/gnm11/GNM11-1.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: left;">
                    {{ $t('GNM11.productComposition') }}
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1); font-weight: bold; text-align: left;">
                    <li v-for="(item, index) in $tm('GNM11.compositionList')" :key="index">{{ item }}</li>
                </ul>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1); font-weight: bold; text-align: left;">
                    {{ $t('GNM11.processorDescription') }}
                </p>
            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/模组/gnm11/GNM11-2.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: left;">
                    {{ $t('GNM11.productFeatures') }}
                </h3>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1); font-weight: bold; text-align: left;">
                    {{ $t('GNM11.featureDescription') }}
                </p>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1); font-weight: bold; text-align: left;">
                    <li v-for="(item, index) in $tm('GNM11.featureList')" :key="index">{{ item }}</li>
                </ul>



            </div>


        </div>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <p class="text-size-64" style="text-align: center; color: rgb(0, 0, 0); font-weight: bold; text-align: center;">
            {{ $t('GNM11.performanceParameters') }}
        </p>

    </div>

    <div class="div-spacer-h80"></div>




    <div class="h-c">
        <div class="v-c">

            <SpecTable :specData="[
                {
                    key: $t('GNM11.specs.performance.title'),
                    tableData: [
                        {
                            title: $t('GNM11.specs.performance.system.title'),
                            contentList: [$t('GNM11.specs.performance.system.content')]
                        },
                        {
                            title: $t('GNM11.specs.performance.channels.title'),
                            contentList: [$t('GNM11.specs.performance.channels.content')]
                        },
                        {
                            title: $t('GNM11.specs.performance.signals.title'),
                            contentList: $tm('GNM11.specs.performance.signals.content')
                        },
                        {
                            title: $t('GNM11.specs.performance.interfaces.title'),
                            contentList: $tm('GNM11.specs.performance.interfaces.content')
                        },
                        {
                            title: $t('GNM11.specs.performance.horizontalAccuracy.title'),
                            contentList: $tm('GNM11.specs.performance.horizontalAccuracy.content')
                        },
                        {
                            title: $t('GNM11.specs.performance.verticalAccuracy.title'),
                            contentList: $tm('GNM11.specs.performance.verticalAccuracy.content')
                        },
                        {
                            title: $t('GNM11.specs.performance.outputFrequency.title'),
                            contentList: $tm('GNM11.specs.performance.outputFrequency.content')
                        },
                        {
                            title: $t('GNM11.specs.performance.ttff.title'),
                            contentList: $tm('GNM11.specs.performance.ttff.content')
                        },
                        {
                            title: $t('GNM11.specs.performance.rtkInit.title'),
                            contentList: [$t('GNM11.specs.performance.rtkInit.content')]
                        },
                        {
                            title: $t('GNM11.specs.performance.reacquisition.title'),
                            contentList: [$t('GNM11.specs.performance.reacquisition.content')]
                        },
                        {
                            title: $t('GNM11.specs.performance.timing.title'),
                            contentList: [$t('GNM11.specs.performance.timing.content')]
                        },
                        {
                            title: $t('GNM11.specs.performance.velocity.title'),
                            contentList: [$t('GNM11.specs.performance.velocity.content')]
                        }
                    ]
                },
                {
                    key: $t('GNM11.specs.physical.title'),
                    tableData: [
                        {
                            title: $t('GNM11.specs.physical.model.title'),
                            contentList: [$t('GNM11.specs.physical.model.content')]
                        },
                        {
                            title: $t('GNM11.specs.physical.size.title'),
                            contentList: [$t('GNM11.specs.physical.size.content')]
                        },
                        {
                            title: $t('GNM11.specs.physical.package.title'),
                            contentList: [$t('GNM11.specs.physical.package.content')]
                        },
                        {
                            title: $t('GNM11.specs.physical.weight.title'),
                            contentList: [$t('GNM11.specs.physical.weight.content')]
                        },
                        {
                            title: $t('GNM11.specs.physical.power.title'),
                            contentList: [$t('GNM11.specs.physical.power.content')]
                        },
                        {
                            title: $t('GNM11.specs.physical.voltage.title'),
                            contentList: [$t('GNM11.specs.physical.voltage.content')]
                        }
                    ]
                },
                {
                    key: $t('GNM11.specs.environment.title'),
                    tableData: [
                        {
                            title: $t('GNM11.specs.environment.workingTemp.title'),
                            contentList: [$t('GNM11.specs.environment.workingTemp.content')]
                        },
                        {
                            title: $t('GNM11.specs.environment.storageTemp.title'),
                            contentList: [$t('GNM11.specs.environment.storageTemp.content')]
                        }
                    ]
                }
            ]" />

        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c" style="justify-content: center;">
            <router-link to="/about/contact" style="text-align: center; width: 100%;">
        <AdapButtomBlack text="获取详细产品信息" />
      </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0); font-weight: bold; text-align: center;">
            {{ $t('GNM11.applicationScenarios') }}
        </h3>


    </div>
    <div class="div-spacer-h80"></div>


    <div class="h-c">

        <div v-show="!isMobile" class="v-c">
            <ScrollingCards2 :items="ScrollingCards2_items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards2 :items="ScrollingCards2_items" />
        </div>





    </div>


    <div class="div-spacer-h288"></div>





</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner车规产品/科技正圆+GNM11.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}





</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards2 from '@/components/ScrollingCards2.vue';
import mScrollingCards2 from '@/components/mScrollingCards2.vue';
const ScrollingCards2_items = [
    { image: require('@/assets/images/官网开发素材/inertia/p2.jpg'), title: '隧道内岔路口', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p3.jpg'), title: '高架桥下', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p4.jpg'), title: '地下停车场', desc: '无卫星信号' },
    { image: require('@/assets/images/官网开发素材/inertia/p6.jpg'), title: '城市峡谷', desc: '卫星信号弱，多路劲效应影响' },
    { image: require('@/assets/images/官网开发素材/inertia/p5.jpg'), title: '复杂立体交通', desc: '卫星信号无/弱' }
]; import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import FeatureCard from '@/components/FeatureCard.vue';
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtom from '@/components/AdapButtom.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>
<template>
  <div class="cards-swiper">
    <swiper 
      class="swiper" 
      :modules="modules" 
      :space-between="20"
      :slides-per-view="'auto'"
      :loop="false"
    >
      <swiper-slide v-for="(item, index) in items" :key="index" class="card">
        <div class="card-inner" :style="{ backgroundImage: `url(${item.image})` }">
          <div class="card-content">
            <h3 style="color: rgb(255, 255, 255); font-weight: bold; text-align: center;" class="text-size-36">
              {{ item.title }}
            </h3>
            <h3 style="color: rgb(255, 255, 255); text-align: center;" class="text-size-14">
              {{ item.desc }}
            </h3>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Virtual } from 'swiper/modules';
import 'swiper/css';

const modules = [Virtual];

defineProps({
  items: {
    type: Array,
    required: true,
  },
});
</script>

<style scoped>
.cards-swiper {
  margin: 20px;
  width: 100%;
  height: 400px;
  position: relative;
}

.card {
  width: 300px !important;
  height: 400px;
}

.card-inner {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: var(--cx-radius);
  position: relative;
}

.card-content {
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
}

:deep(.swiper) {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow: visible;
}

:deep(.swiper-slide) {
  width: auto;
}
</style>
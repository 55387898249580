import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

//【首页】
import HomeView from '@/views/HomeView.vue'//首页✅

//【产品服务】
import AutomotiveIndex from '@/views/product/AutomotiveIndex.vue'//卫惯系列/车规级卫惯产品index⬇️
import IB11 from '@/views/product/automotive/IB11.vue'//卫惯系列/车规级卫惯产品/IB11
import P21S from '@/views/product/automotive/P21S.vue'//卫惯系列/车规级卫惯产品/P21S
import P22D from '@/views/product/automotive/P22D.vue'//卫惯系列/车规级卫惯产品/P22D
import CxpFusion from '@/views/product/automotive/CxpFusion.vue'//卫惯系列/车规级卫惯产品/cxpfusion
import INM11 from '@/views/product/automotive/INM11.vue'//卫惯系列/车规级卫惯产品/INM11
import GNM11 from '@/views/product/automotive/GNM11.vue'//卫惯系列/车规级卫惯产品/GNM11

//卫惯系列/车规级卫惯产品/IMU模组系列index⬇️(互相跳转没有单独的index)
import IM11 from '@/views/product/automotive/imu/IM11.vue'//卫惯系列/车规级卫惯产品/IMU模组系列/IM11
import IM12 from '@/views/product/automotive/imu/IM12.vue'//卫惯系列/车规级卫惯产品/IMU模组系列/IM12
import IM13 from '@/views/product/automotive/imu/IM13.vue'//卫惯系列/车规级卫惯产品/IMU模组系列/IM13
import IM15 from '@/views/product/automotive/imu/IM15.vue'//卫惯系列/车规级卫惯产品/IMU模组系列/IM15(板对板)



import IndustryIndex from '@/views/product/IndustryIndex.vue'//卫惯系列/行业级卫惯产品index⬇️
import CXGY10 from '@/views/product/industry/CXGY10.vue'//卫惯系列/行业级卫惯产品/CXGY-10 IMU
import CXGY20 from '@/views/product/industry/CXGY20.vue'//卫惯系列/行业级卫惯产品/CXGY-20 VRU
import CXGY30 from '@/views/product/industry/CXGY30.vue'//卫惯系列/行业级卫惯产品/CXGY-30 AHRS
import CXGY50 from '@/views/product/industry/CXGY50.vue'//卫惯系列/行业级卫惯产品/CXGY-50 INS-BOX

import CXGY110 from '@/views/product/industry/CXGY110.vue'//卫惯系列/行业级卫惯产品/CXGY-110 IMU
import CXGY150 from '@/views/product/industry/CXGY150.vue'//卫惯系列/行业级卫惯产品/CXGY-150 INS-BOX双天线

import CXGYB10 from '@/views/product/industry/CXGYB10.vue'//卫惯系列/行业级卫惯产品/CXGY-B10
import CXGYB110 from '@/views/product/industry/CXGYB110.vue'//卫惯系列/行业级卫惯产品/CXGY-B110
import CXGYB120 from '@/views/product/industry/CXGYB120.vue'//卫惯系列/行业级卫惯产品/CXGY-B120
import CXGYB130 from '@/views/product/industry/CXGYB130.vue'//卫惯系列/行业级卫惯产品/CXGY-B130


import algorithm from '@/views/service/algorithm.vue'//算法算法服务index
import SmartCockpit from '@/views/service/SmartCockpit.vue' //智能座舱index
import software from '@/views/service/software.vue'//软件服务index

//【解决方案】

import SolutionIndex from '@/views/solution/SolutionIndex.vue'//解决方案index
import SolutionView1 from '@/views/solution/SolutionView1.vue'//低速无人车
import SolutionView2 from '@/views/solution/SolutionView2.vue'//智能机器人
import SolutionView3 from '@/views/solution/SolutionView3.vue'//自动化工程器械
import SolutionView4 from '@/views/solution/SolutionView4.vue'//自动化农机
import SolutionView5 from '@/views/solution/SolutionView5.vue'//无人飞行器
import SolutionView6 from '@/views/solution/SolutionView6.vue'//港口船舶
import SolutionView7 from '@/views/solution/SolutionView7.vue'//智能驾驶汽车
import SolutionView8 from '@/views/solution/SolutionView8.vue'//智慧测绘仪器

//【量产能力】
import DeliverIndex from '@/views/deliver/DeliverIndex.vue'//量产能力index

//【公司动态】
import NewsIndex from '@/views/news/NewsIndex.vue'//公司动态index

//【关于我们】
import Contact from '@/views/about/Contact.vue'//联系我们
//企业概况 -> lazy load








//测试页面
import MagneticScrollingTemplate from '@/views/ttt/MagneticScrollingTemplate.vue'
import TestView from '@/views/ttt/TestView.vue'
import tmp from '@/views/ttt/tmp.vue'



// const routes: Array<RouteRecordRaw> = [

//   {
//     path: '/Product/IM11',
//     name: 'IM11',
//     component: IM11
//   },
//   {
//     path: '/DeliverIndex',
//     name: 'DeliverIndex',
//     component: DeliverIndex
//   },
//   {
//     path: '/Solution/View8',
//     name: 'SolutionView8',
//     component: SolutionView8
//   },
//   {
//     path: '/Solution/View7',
//     name: 'SolutionView7',
//     component: SolutionView7
//   },
//   {
//     path: '/Solution/View6',
//     name: 'SolutionView6',
//     component: SolutionView6
//   },
//   {
//     path: '/Solution/View5',
//     name: 'SolutionView5',
//     component: SolutionView5
//   },
//   {
//     path: '/Solution/View4',
//     name: 'SolutionView4',
//     component: SolutionView4
//   },
//   {
//     path: '/Solution/View3',
//     name: 'SolutionView3',
//     component: SolutionView3
//   },
//   {
//     path: '/Solution/View2',
//     name: 'SolutionView2',
//     component: SolutionView2
//   },
//   {
//     path: '/Solution/View1',
//     name: 'SolutionView1',
//     component: SolutionView1
//   },
//   {
//     path: '/MagneticScrollingTemplate',
//     name: 'MagneticScrollingTemplate',
//     component: MagneticScrollingTemplate
//   },
//   {
//     path: '/Service/View2',
//     name: 'software',
//     component: software
//   },
//   {
//     path: '/Service/View1',
//     name: 'algorithm',
//     component: algorithm
//   },
//   {
//     path: '/Product/CxpFusion',
//     name: 'CxpFusion',
//     component: CxpFusion
//   },
//   {
//     path: '/Product/View1',
//     name: 'AutomotiveIndex',
//     component: AutomotiveIndex
//   },
//   {
//     path: '/tmp',
//     name: 'tmp',
//     component: tmp
//   },
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/test',
//     name: 'test',
//     component: TestView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '@/views/about/AboutView.vue')
//   }
// ]
const routes: Array<RouteRecordRaw> = [
  // 首页
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  // 产品
  {
    path: '/product',
    redirect: '/product/automotive', // 建议添加重定向到默认子路由
    children: [
      // 车规级卫惯产品
      {
        path: 'automotive',
        children: [
          {
            path: '',
            name: 'AutomotiveIndex',
            component: AutomotiveIndex
          },
          {
            path: 'ib11',
            name: 'IB11',
            component: IB11
          },
          {
            path: 'p21s',
            name: 'P21S',
            component: P21S
          },
          {
            path: 'p22d',
            name: 'P22D',
            component: P22D
          },
          {
            path: 'cxp-fusion',
            name: 'CxpFusion',
            component: CxpFusion
          },
          {
            path: 'inm11',
            name: 'INM11',
            component: INM11
          },
          {
            path: 'gnm11',
            name: 'GNM11',
            component: GNM11
          },
          // IMU模组系列
          {
            path: 'imu',
            redirect: '/product/automotive', // 添加重定向

            children: [
              {
                path: 'im11',
                name: 'IM11',
                component: IM11
              },
              {
                path: 'im12',
                name: 'IM12',
                component: IM12
              },
              {
                path: 'im13',
                name: 'IM13',
                component: IM13
              },
              {
                path: 'im15',
                name: 'IM15',
                component: IM15
              }
            ]
          }
        ]
      },
      // 行业级卫惯产品
      {
        path: 'industry',
        children: [
          {
            path: '',
            name: 'IndustryIndex',
            component: IndustryIndex
          },
          {
            path: 'cxgy10',
            name: 'CXGY10',
            component: CXGY10
          },
          {
            path: 'cxgy20',
            name: 'CXGY20',
            component: CXGY20
          },
          {
            path: 'cxgy30',
            name: 'CXGY30',
            component: CXGY30
          },
          {
            path: 'cxgy50',
            name: 'CXGY50',
            component: CXGY50
          },
          {
            path: 'cxgy110',
            name: 'CXGY110',
            component: CXGY110
          },
          {
            path: 'cxgy150',
            name: 'CXGY150',
            component: CXGY150
          },
          {
            path: 'cxgyb10',
            name: 'CXGYB10',
            component: CXGYB10
          },
          {
            path: 'cxgyb110',
            name: 'CXGYB110',
            component: CXGYB110
          },
          {
            path: 'cxgyb120',
            name: 'CXGYB120',
            component: CXGYB120
          },
          {
            path: 'cxgyb130',
            name: 'CXGYB130',
            component: CXGYB130
          }
        ]
      }
    ]
  },

  // 服务
  {
    path: '/service',
    redirect: '/product/automotive/imu/im11', // 添加重定向
    children: [
      {
        path: 'algorithm',
        name: 'algorithm',
        component: algorithm
      },
      {
        path: 'smart-cockpit',
        name: 'SmartCockpit',
        component: SmartCockpit
      },
      {
        path: 'software',
        name: 'software',
        component: software
      }
    ]
  },

  // 解决方案
  {
    path: '/solution',
    children: [
      {
        path: '',
        name: 'SolutionIndex',
        component: SolutionIndex
      },
      {
        path: 'low-speed-vehicle',
        name: 'SolutionView1',
        component: SolutionView1
      },
      {
        path: 'smart-robot',
        name: 'SolutionView2',
        component: SolutionView2
      },
      {
        path: 'engineering-machinery',
        name: 'SolutionView3',
        component: SolutionView3
      },
      {
        path: 'agricultural-machinery',
        name: 'SolutionView4',
        component: SolutionView4
      },
      {
        path: 'uav',
        name: 'SolutionView5',
        component: SolutionView5
      },
      {
        path: 'port-shipping',
        name: 'SolutionView6',
        component: SolutionView6
      },
      {
        path: 'smart-driving',
        name: 'SolutionView7',
        component: SolutionView7
      },
      {
        path: 'smart-surveying',
        name: 'SolutionView8',
        component: SolutionView8
      }
    ]
  },

  // 量产能力
  {
    path: '/deliver',
    name: 'DeliverIndex',
    component: DeliverIndex
  },

  // 公司动态
  {
    path: '/news',
    name: 'NewsIndex',
    component: NewsIndex
  },

  // 关于我们
  {
    path: '/about',
    children: [
      {
        path: '',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '@/views/about/AboutView.vue')
      },
      {
        path: 'contact',
        name: 'Contact',
        component: Contact
      }
    ]
  },

  // // 测试页面
  // {
  //   path: '/test',
  //   children: [
  //     {
  //       path: '',
  //       name: 'test',
  //       component: TestView
  //     },
  //     {
  //       path: 'magnetic-scrolling',
  //       name: 'MagneticScrollingTemplate',
  //       component: MagneticScrollingTemplate
  //     },
  //     {
  //       path: 'tmp',
  //       name: 'tmp',
  //       component: tmp
  //     }
  //   ]
  // }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

// 添加全局导航守卫
router.beforeEach((to, from, next) => {
  // 检查是否有匹配的路由
  if (to.matched.length === 0) {
    // 如果没有匹配的路由，重定向到首页
    next({ path: '/' })
  } else {
    // 存在匹配的路由，正常导航
    next()
  }
})

export default router
<!-- 公规的应用场景卡组 -->
<template>
  <div class="scrolling-cards">
    <div class="card-container" :style="{ transform: `translateX(${transformValue}px)` }">
      <div class="card" v-for="(item, index) in extendedItems" :key="index" @mouseover="stopScrolling"
        @mouseout="startScrolling" :style="{ backgroundImage: `url(${item.image})` }">
        <router-link :to="item.url">

          <div class="card-content">
            <h3 style="color: rgb(255, 255, 255);
              
              
              font-weight: bold;
              
              text-align: center;" class="text-size-36">
              {{ item.title }}
            </h3>

          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onBeforeUnmount } from 'vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
});

const transformValue = ref(0); // 控制位移
const isScrollingStopped = ref(false);
const scrollSpeed = ref(-1); // 滚动速度
const cardWidth = 320; // 卡片宽度 + 间距（300px宽度 + 20px间距）

const extendedItems = computed(() => {
  const screenWidth = window.innerWidth; // 获取屏幕宽度
  const cardsOnScreen = Math.ceil(screenWidth / cardWidth); // 计算当前屏幕可显示多少张卡片
  const itemsToClone = Math.max(cardsOnScreen, 10); // 至少克隆10个
  const cloneCount = Math.ceil(itemsToClone / props.items.length); // 计算需要克隆多少次

  let clonedItems = [];

  for (let i = 0; i < cloneCount; i++) {
    clonedItems = [...clonedItems, ...props.items]; // 每次将原项目添加进扩展数组
  }

  return clonedItems.slice(0, itemsToClone + props.items.length); // 确保扩展列表的长度
});


// 启动滚动
function startScrolling() {
  isScrollingStopped.value = false;
  window.requestAnimationFrame(scroll);
}

// 停止滚动
function stopScrolling() {
  isScrollingStopped.value = true;
}

// 滚动函数
function scroll() {
  if (!isScrollingStopped.value) {
    transformValue.value += scrollSpeed.value;

    // 当滚动到克隆的卡片时，重置位移到初始位置
    if (Math.abs(transformValue.value) >= cardWidth * props.items.length) {
      transformValue.value = 0;
    }

    window.requestAnimationFrame(scroll);
  }
}



// 当组件挂载时开始滚动并监听窗口尺寸变化
onMounted(() => {
  startScrolling();


});

</script>

<style scoped>
.scrolling-cards {
  margin: 20px;
  
  width: 100%;
  height: 400px;
  position: relative;
}

.card-container {
  display: flex;
  transition: none;
  border-radius: var(--cx-radius);

}

.card {
  flex: 0 0 auto;
  width: 300px;
  /* 固定卡片宽度 */
  height: 400px;
  margin-right: 20px;
  background-size: cover;
  background-position: center;
  border-radius: var(--cx-radius);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* 添加平滑的过渡效果 */

}

.card:hover {
  transform: scale(1.1);
  /* 鼠标悬停时放大1.1倍 */
  z-index: 1;
  /* 确保放大的卡片位于其他卡片之上 */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  /* 添加阴影效果 */
}


.card-content {
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
  text-align: center;
  padding: 10px;
}
</style>

<template>
  <!-- 定义一个图片组件，用于显示返回顶部按钮 -->
  <img v-show="!isMobile" src="@/assets/images/svgs/up.svg" class="toup" :class="{ 'toup-active': isShowUpBtn }" @click="toTop" />
</template>

<script setup>
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import { ref, onMounted, onUnmounted } from 'vue';

// 定义一个响应式变量，用于控制返回顶部按钮的显示状态
const isShowUpBtn = ref(false);

// 定义一个处理滚动事件的函数，当用户滚动页面时，会根据滚动距离来显示或隐藏返回顶部按钮
const handleScroll = () => {
  const top = document.documentElement.scrollTop || document.body.scrollTop;
  isShowUpBtn.value = top > 200; // 当滚动超过200px时显示按钮
};

// 定义一个平滑滚动到顶部的函数，点击返回顶部按钮时调用此函数
const toTop = () => {
  const scrollToTop = () => {
    let top = document.documentElement.scrollTop || document.body.scrollTop;
    if (top > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, top - top / 8);
    }
  };
  scrollToTop();
};

// 在组件挂载时，添加滚动事件监听器
onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

// 在组件卸载时，移除滚动事件监听器
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped>
/* 定义返回顶部按钮的样式: 默认不透明，固定在页面右侧底部，宽高均为50px，鼠标指针样式为指针，过渡效果为0.25秒 */
.toup {
  opacity: 0;
  position: fixed;
  z-index: 11;
  right: 50px;
  bottom: 100px;
  display: block;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: all.25s ease;
}

/* 当按钮处于激活状态时，设置其透明度为1，即完全显示 */
.toup-active {
  opacity: 1;
}
</style>

//SpecTable.vue
<script setup>
import { ref } from 'vue'
import { useScreenWidth } from '@/js/useScreenWidth'
import AdapButtom from '@/components/AdapButtom.vue'
import TableLine from '@/components/TableLine.vue'

const props = defineProps({
  specData: {
    type: Array,
    required: true
  }
})

const isMobile = useScreenWidth()
const currentKey = ref(props.specData[0]?.key || '')
const expandedPanels = ref(new Set())

const togglePanel = (key) => {
  if (expandedPanels.value.has(key)) {
    expandedPanels.value.delete(key)
  } else {
    expandedPanels.value.add(key)
  }
}
</script>

<template>
  <div class="spec-table">
    <!-- PC端按钮组 -->
    <div v-if="!isMobile" class="spec-table__buttons">
      <AdapButtom v-for="item in specData" :key="item.key" :text="item.key" @mouseover="currentKey = item.key" />
    </div>

    


    <!-- PC端内容区 -->
    <div v-if="!isMobile" class="spec-table__content">
      <template v-for="item in specData" :key="item.key">
        <div v-show="currentKey === item.key">
          <TableLine v-for="(row, index) in item.tableData" :key="index" :title="row.title"
            :contentList="row.contentList" />
        </div>
      </template>
    </div>

    <!-- 移动端折叠面板 -->
    <div v-else class="spec-table__mobile">
      <div v-for="item in specData" :key="item.key" class="spec-table__panel">
        <div class="spec-table__panel-header" @click="togglePanel(item.key)">
          <span class="spec-table__panel-title">{{ item.key }}</span>
          <span class="spec-table__panel-arrow">
            {{ expandedPanels.has(item.key) ? '▲' : '▼' }}
          </span>
        </div>
        <div v-show="expandedPanels.has(item.key)" class="spec-table__panel-content">
          <TableLine v-for="(row, index) in item.tableData" :key="index" :title="row.title"
            :contentList="row.contentList" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.spec-table__buttons {
  justify-content: center;
  display: flex;
  gap: 40px;
  margin-bottom: 24px;
  padding-bottom: 60px;
  border-bottom: 0.5px solid rgb(217, 217, 217);
}

.spec-table__mobile {
  display: flex;
  flex-direction: column;
}

.spec-table__panel {
  border-bottom: 1px solid #eee;
}

.spec-table__panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 16px; */
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  background: #fff;
  font-size: 20px;
}

.spec-table__panel-arrow {
  text-align: right !important;
  font-size: 12px;
  color: #666;
  width: fit-content;
}

.spec-table__panel-content {
  background: #fff;
}

.spec-table__panel-title {
  text-align: left !important;
}
</style>
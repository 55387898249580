<!-- 解决方案-智能驾驶汽车 -->


<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255); font-weight: bold; text-align: left;">
                    {{ $t('SolutionView7.title') }}
                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(255, 255, 255); text-align: left;">
                    {{ $t('SolutionView7.description') }}
                </p>


            </div>





        </div>

    </div>
<div class="div-spacer-h288"></div>

    <div class="h-c">
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/自动驾驶汽车/车1.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 275px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: left;">
                    {{ $t('SolutionView7.smartDrivingTitle') }}
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0); text-align: left;">
                    {{ $t('SolutionView7.smartDrivingDesc') }}
                </p>
            </div>

        </div>
    </div>
    <div class="div-spacer-h120"></div>
    <div class="div-spacer-h120"></div>

    <div class="h-c">
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: center;">
                    {{ $t('SolutionView7.painPointTitle') }}
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0); text-align: left;">
                    {{ $t('SolutionView7.painPointDesc') }}
                </p>

            </div>

        </div>

    </div>


    <div class="div-spacer-h120"></div>
    <div class="div-spacer-h120"></div>



    <div class="h-c">
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/自动驾驶汽车/车2.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: left;">
                    {{ $t('SolutionView7.advantageTitle') }}
                </h3>



















                <ul class="text-size-16" style="color: rgba(89, 89, 89,1); text-align: left; list-style-position: inside;">
                    <li>{{ $t('SolutionView7.advantage1') }}</li>
                    <li>{{ $t('SolutionView7.advantage2') }}</li>
                    <li>{{ $t('SolutionView7.advantage3') }}</li>
                    <li>{{ $t('SolutionView7.advantage4') }}</li>
                    <li>{{ $t('SolutionView7.advantage5') }}</li>




                </ul>
            </div>

        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0); text-align: center;">
            {{ $t('SolutionView7.relatedProducts') }}
        </h3>

    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c">


        <RelatedScrollingCards3 :products="RelatedScrollingCards3Products" :visibleImages="2" />

    </div>
    <div class="div-spacer-h288"></div>









</template>



<style scoped>
.banner {

    
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("@/assets/images/官网开发素材/banner/行业解决方案banner/自动驾驶.jpg");
    z-index: -1;

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果

import PictureCard from '@/components/PictureCard.vue';
import RelatedScrollingCards3 from '@/components/RelatedScrollingCards3.vue';
const RelatedScrollingCards3Products = [
  {
    imageUrl: require("@/assets/images/官网开发素材/车规产品/整机/IB11/IB11-1.jpg"),
    title: "IB11",
    description: "IMU-BOX整机",
    link: "/product/automotive/ib11"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/车规产品/整机/P21S/P21S-7.jpg"),
    title: "P21S",
    description: "单天线高精定位整机",
    link: "/product/automotive/p21s"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/车规产品/整机/P22D/P22D1.jpg"),
    title: "P22D",
    description: "双天线高精定位整机",
    link: "/product/automotive/p22d"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/车规产品/cxfusion/cxpfusion1.jpg"),
    title: "CXP-FUSION",
    description: "微型高精定位智联终端",
    link: "/product/automotive/cxp-fusion"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/车规产品/模组/inm11/cx-inm003sa16.jpg"),
    title: "INM11",
    description: "高精定位-模组/板卡",
    link: "/product/automotive/inm11"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/车规产品/模组/IM11/im11-2.jpg"),
    title: "IMU模组系列",
    description: "惯性测量单元系列",
    link: "/product/automotive/imu/im11"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/车规产品/模组/gnm11/GNM11-1.jpg"),
    title: "GNM11",
    description: "GNSS模组",
    link: "/product/automotive/gnm11"
  }
  
]




</script>
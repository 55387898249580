<!-- 响应式版本的newSummaryCard2,会侦测是否在移动端 -->
<template>
    <div v-if="!isMobile" class="card-container">
        <div 
            class="feature-card"
            :class="{ 'is-hovered': isHovered }"
            @mouseenter="isHovered = true"
            @mouseleave="isHovered = false"
            :style="cardStyle"
        >
            <div class="number">
                <h2 class="text-size-64">{{ number }}</h2>
            </div>
            <div class="content">
                <h2 class="title text-size-24">{{ title }}</h2>
                <p class="description text-size-14">{{ description }}</p>
            </div>
        </div>
    </div>


    <div v-if="isMobile" class="card-container">
        <div 
            class="feature-card text-size-24"
            style="width: 100%; text-align:center;"
            :class="{ 'is-hovered': isHovered }"
            @mouseenter="isHovered = true"
            @mouseleave="isHovered = false"
            :style="cardStyle"
        >{{ title }}

            
        </div>
    </div>




</template>

<script setup>
import { ref, computed } from 'vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()

defineProps({
    number: {
        type: [String, Number],
        default: 1
    },
    title: {
        type: String,
        default: '智能驾驶汽车'
    },
    description: {
        type: String,
        default: '智能驾驶汽车行业是当今科技领域最具活力和发展潜力的行业之一，且需要雇佣来做的会持续稳定'
    }
});

const isHovered = ref(false);

const cardStyle = computed(() => ({
    transform: `scale(${isHovered.value ? 1.02 : 1})`,
    transition: 'all 500ms ease-in-out'
}));
</script>



<style scoped>
.card-container {
    

    
    flex: 1;
    min-width: 0; /* 防止flex子项溢出 */
    height: 100%;
}

.feature-card {
    
    backdrop-filter: blur(30px);
    background: rgb(245, 245, 247);
    
    border-radius: var(--cx-radius);
    padding: 16px;
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 1rem;
    cursor: pointer;
    
    height: 100%;
}

.feature-card.is-hovered {
    background: white;
    box-shadow: 0px 5px 27px 2px rgba(0, 0, 0, 0.05);
}

.number {
    
    
    font-weight: 700;
    color: #374151;
    line-height: 1;
    transition: color 500ms ease-in-out;
    padding-right: 0.5rem;
}

.feature-card.is-hovered .number {
    color: #111827;
}

.content {

    min-width: 0; /* 防止内容溢出 */
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.title {
    
    font-weight: 600;
    color: #374151;
    margin: 0;
    transition: color 500ms ease-in-out;
    white-space: nowrap; /* 标题不换行 */
}

.feature-card.is-hovered .title {
    color: #111827;
}

.description {
    
    color: #6B7280;
    line-height: 1.5;
    margin: 0;
    transition: color 500ms ease-in-out;
}

.feature-card.is-hovered .description {
    color: #4B5563;
}

@media (max-width: 768px) {

    .feature-card {

        grid-template-columns: 1fr;
        
    }

    .card-container {
    
        height: fit-content;
    }

}

</style>
<!-- 产品服务-算法服务 -->

<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 935px;">
                <h1 class="text-size-72" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    {{ $t('algorithm.title') }}
                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    {{ $t('algorithm.description') }}
                </p>


            </div>





        </div>

    </div>



    <div class="bgc div-spacer-h288"></div>


    <div class="bgc h-c">

        <GroupNameCard :title="$t('algorithm.features.title')" :describe="$t('algorithm.features.describe')" />

    </div>

    <div class="bgc div-spacer-h80"></div>

    <div class="bgc h-c">
        <div v-show="!isMobile" class="v-c">
            <div class="g-c-5-1-1">
                <AdvantageCard3 
                    :title="$t('algorithm.advantage1.title')" 
                    :describe="$t('algorithm.advantage1.describe')"
                    :src="require('@/assets/images/官网开发素材/算法/1.svg')" />
                <AdvantageCard3 
                    :title="$t('algorithm.advantage2.title')" 
                    :describe="$t('algorithm.advantage2.describe')"
                    :src="require('@/assets/images/官网开发素材/算法/2.svg')" />
                <AdvantageCard3 
                    :title="$t('algorithm.advantage3.title')" 
                    :describe="$t('algorithm.advantage3.describe')"
                    :src="require('@/assets/images/官网开发素材/算法/3.svg')" />
                <AdvantageCard3 
                    :title="$t('algorithm.advantage4.title')" 
                    :describe="$t('algorithm.advantage4.describe')"
                    :src="require('@/assets/images/官网开发素材/算法/4.svg')" />
                <AdvantageCard3 
                    :title="$t('algorithm.advantage5.title')" 
                    :describe="$t('algorithm.advantage5.describe')"
                    :src="require('@/assets/images/官网开发素材/算法/5.svg')" />
            </div>
        </div>
        <div v-show="isMobile" class="v-c">
            <div class="g-c-5-1-1" style="z-index: 1;">
                <AdvantageCard2 
                    :title="$t('algorithm.advantage1.title')" 
                    :describe="$t('algorithm.advantage1.describe')"
                    :src="require('@/assets/images/官网开发素材/算法/1.svg')" />
                <AdvantageCard2 
                    :title="$t('algorithm.advantage2.title')" 
                    :describe="$t('algorithm.advantage2.describe')"
                    :src="require('@/assets/images/官网开发素材/算法/2.svg')" />
                <AdvantageCard2 
                    :title="$t('algorithm.advantage3.title')" 
                    :describe="$t('algorithm.advantage3.describe')"
                    :src="require('@/assets/images/官网开发素材/算法/3.svg')" />
                <AdvantageCard2 
                    :title="$t('algorithm.advantage4.title')" 
                    :describe="$t('algorithm.advantage4.describe')"
                    :src="require('@/assets/images/官网开发素材/算法/4.svg')" />
                <AdvantageCard2 
                    :title="$t('algorithm.advantage5.title')" 
                    :describe="$t('algorithm.advantage5.describe')"
                    :src="require('@/assets/images/官网开发素材/算法/5.svg')" />
            </div>
        </div>

    </div>

    <div class="bgc div-spacer-h288"></div>

    <div class="bgc h-c">

        <GroupNameCard :title="$t('algorithm.composition.title')" :describe="$t('algorithm.composition.describe')" />

    </div>
    <div class="bgc div-spacer-h80"></div>
    <div class="bgc h-c">
        <div class="bgc v-c">
            <ProductCompositionCard 
                :image="require('@/assets/images/官网开发素材/算法/算法库.jpg')" 
                :title="$t('algorithm.product1.title')"
                :description="$t('algorithm.product1.description')" />
            <ProductCompositionCard 
                :image="require('@/assets/images/官网开发素材/算法/场景配置文件.jpg')" 
                :title="$t('algorithm.product2.title')"
                :description="$t('algorithm.product2.description')" />
            <ProductCompositionCard 
                :image="require('@/assets/images/官网开发素材/算法/SDK说明文件.jpg')" 
                :title="$t('algorithm.product3.title')"
                :description="$t('algorithm.product3.description')" />
            <ProductCompositionCard 
                :image="require('@/assets/images/官网开发素材/算法/调用demo演示程序.jpg')" 
                :title="$t('algorithm.product4.title')"
                :description="$t('algorithm.product4.description')" />


        </div>
    </div>

    <div class="bgc div-spacer-h288"></div>



</template>



<style scoped>

.banner {

    background-image: url("@/assets/images/官网开发素材/banner/算法banner.jpg");
    
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}






.bgc{
    background-color: rgb(245, 245, 247);
}

</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import AdvantageCard2 from '@/components/AdvantageCard2.vue';
import ProductCompositionCard from '@/components/ProductCompositionCard.vue';
import AdvantageCard3 from '@/components/AdvantageCard3.vue';
import GroupNameCard from '@/components/GroupNameCard.vue';

</script>
<template>
    <button class="AdapButtom text-size-16" :style="buttonStyle" @click="handleClick">
      {{ buttonText }}
    </button>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue';
  
  // 定义 props 接收从父组件传递过来的文本
  const props = defineProps({
    text: {
      type: String,
      required: true
    }
  });
  
  // 响应式的按钮文本
  const buttonText = ref(props.text);
  
  // 计算按钮的样式
  const buttonStyle = computed(() => ({
    boxSizeing: 'border-box',
    borderRadius: '39px',
    color: 'rgb(0, 0, 0)', // 文本颜色可以根据需要调整
    padding: '10px 20px', // 内边距可以根据需要调整
    border: '1px solid rgb(0, 0, 0)', // 如果不需要边框，可以设置为 none
    cursor: 'pointer',// 鼠标指针悬停时变为手形
    width: 'fit-content', // 让按钮宽度刚好适合内容
    backgroundColor: 'transparent', // 设置背景颜色为透明
    fontWeight: 'bold', // 字体加粗

  }));
  
  // 按钮点击事件处理函数
  function handleClick() {
    // alert(`Button with text "${buttonText.value}" was clicked!`);
  }
  </script>
  
  <style scoped>

  /* 你可以在这里添加更多的样式 */
  button {
    display: inline-block;
    
    text-wrap: nowrap;
    
    /* 字体大小 */
    transition: background-color 0.15s ease, color 0.15s ease !important /* 明确指定过渡属性 */
    /* 平滑过渡效果 */

  }

  button:hover {

    background-color: black !important;
    color: rgb(255, 255, 255) !important;

  }
</style>
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "language-switcher" }

import { useI18n } from 'vue-i18n'
import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguageSwitcher',
  setup(__props) {

const { locale } = useI18n()
const currentLocale = computed(() => locale.value)

const toggleLanguage = () => {
  const newLocale = locale.value === 'zh' ? 'en' : 'zh'
  locale.value = newLocale
  localStorage.setItem('locale', newLocale)
}

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: toggleLanguage,
      class: "lang-btn"
    }, _toDisplayString(currentLocale.value === 'zh' ? 'EN' : '中文'), 1)
  ], 512)), [
    [_vShow, currentLocale.value !== 'zh']
  ])
}
}

})
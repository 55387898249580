<template>
  <footer class="footer">
    
    <div class="h-c">
      <div class="div-spacer-h120"></div>
      <div class="v-c" style="justify-content: space-around;">
        <!-- 第一部分 -->
        <div
          style="display: inline-flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;width: fit-content;">
          <div
            style="width: 100%; height: 40px; display: inline-flex; flex-direction: row; align-items: center; justify-content: center;">
            <img style="max-width: 300px; width: 100%;height: 40px;" src="@/assets/images/svgs/logo.light.svg" />
          </div>
          <div style="height: 28px;"></div>
          <div class="m-con" style="display: inline-flex; flex-direction: row; align-items: flex-start; justify-content: flex-start;">
            <div
              style="display: inline-flex; flex-direction: column; align-items: center; justify-content: flex-start;">
              <img style="width: 100px; height: 100px;" src="@/assets/images/footer/qrcode2.png" />
              <div style="height: 4px;"></div>
              <p class="text-size-10" style="color: white;text-align: center; margin-bottom: 10px;">{{ $t('Footer.wechat') }}</p>
            </div>
            <div style="width: 40px;"></div>
            <div class="ms-con"
              style="display: inline-flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;">
              <div
                style="display: inline-flex; flex-direction: row; align-items: center; justify-content: center;">
                <p class="text-size-12" style=" color: white;width: fit-content;">
                  {{ $t('Footer.support') }}</p>
                <div style="width: 11px;"></div>
                <div
                  style="padding-left: 4px; padding-right: 4px; padding-top: 2px; padding-bottom: 2px; border-radius: 4px; border-color: rgba(255, 254.75, 254.75, 1); border-width: 0.50px; border-style: solid; display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start;width: fit-content;">
                  <p class="text-size-10" style=" color: white;width: min-content; ">
                    IPv4</p>
                </div>
              </div>
              <div style="height: 20px;"></div>
              <p class="text-size-12" style="text-align: center;  color: white;">
                {{ $t('Footer.icp') }}</p>
              <div style="height: 20px;"></div>
              <p class="text-size-12" style=" text-align: center; color: white;">
                {{ $t('Footer.copyright') }}
              </p>
            </div>
          </div>
        </div>

        <!-- 分隔线 -->
        <div v-show="!isMobile" class="separator"></div>
        <div v-show="isMobile" class="separatorM"></div>

        <!-- 第二部分 -->
        <div
          style="display: inline-flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;width: fit-content;">
          <div style="width: 288px; height: 88px; position: relative;">
            <div
              style="left: 0px; top: 0px; position: absolute; display: inline-flex; flex-direction: row; align-items: center; justify-content: flex-start;">
              <div style="width: 13.50px; height: 13.50px;border-radius: 8px;">
                <svg width="13.500000" height="13.500000" viewBox="0 0 13.5 13.5" fill="none"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <desc>
                    Created with Pixso.
                  </desc>
                  <defs />
                  <path id="path"
                    d="M13.5 10.06L13.5 12.71C13.5 13.11 13.19 13.43 12.8 13.46C12.47 13.48 12.2 13.5 12 13.5C5.37 13.5 0 8.12 0 1.5C0 1.29 0.01 1.02 0.03 0.69C0.06 0.3 0.38 0 0.78 0L3.43 0C3.62 0 3.78 0.14 3.8 0.33C3.82 0.5 3.84 0.64 3.85 0.75C4 1.79 4.31 2.8 4.76 3.75C4.83 3.9 4.78 4.08 4.65 4.17L3.03 5.33C4.02 7.64 5.86 9.47 8.16 10.46L9.32 8.85C9.41 8.71 9.6 8.66 9.75 8.74C10.7 9.18 11.71 9.49 12.75 9.64C12.85 9.65 12.99 9.67 13.16 9.69C13.35 9.71 13.5 9.87 13.5 10.06Z"
                    fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
                </svg>

              </div>
              <div style="width: 18px;"></div>
              <p class="text-size-12" style=" color: white;">{{ $t('Footer.contact.phone') }}</p>
            </div>
            <p class="text-size-12"
              style="left: 31px; top: 24px; position: absolute; font-weight: 700; line-height: 20px; color: white;">
              {{ $t('Footer.extension.title') }}</p>
            <p class="text-size-12"
              style="left: 155px; top: 48px; position: absolute;  color: white;">
              {{ $t('Footer.extension.supply') }}<br />{{ $t('Footer.extension.government') }}</p>
            <p class="text-size-12"
              style="left: 31px; top: 48px; position: absolute;  color: white;">
              {{ $t('Footer.extension.business') }}<br />{{ $t('Footer.extension.brand') }}</p>
          </div>
          <div style="height: 20px;"></div>
          <div style="display: inline-flex; flex-direction: row; align-items: center; justify-content: flex-start;">
            <div style="width: 15px; height: 13.50px;border-radius: 8px;">
              <svg width="15.000000" height="13.500000" viewBox="0 0 15 13.5" fill="none"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <desc>
                  Created with Pixso.
                </desc>
                <defs />
                <path id="path"
                  d="M0.75 0L14.25 0C14.66 0 15 0.33 15 0.75L15 12.75C15 13.16 14.66 13.5 14.25 13.5L0.75 13.5C0.33 13.5 0 13.16 0 12.75L0 0.75C0 0.33 0.33 0 0.75 0ZM7.54 6.51L2.73 2.42L1.76 3.57L7.55 8.48L13.24 3.56L12.25 2.43L7.54 6.51Z"
                  fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
              </svg>

            </div>
            <div style="width: 18px;"></div>
            <p class="text-size-12" style=" color: white;">{{ $t('Footer.contact.email') }}</p>
          </div>
          <div style="height: 20px;"></div>
          <div style="display: inline-flex; flex-direction: row; align-items: flex-start; justify-content: flex-start;">
            <div style="width: 13.50px; height: 16.30px; border-radius: 8px;margin-top: 2px;">
              <svg width="13.500000" height="16.296875" viewBox="0 0 13.5 16.2969" fill="none"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <desc>
                  Created with Pixso.
                </desc>
                <defs />
                <path id="path"
                  d="M11.52 11.52L6.75 16.29L1.97 11.52C-0.66 8.88 -0.66 4.61 1.97 1.97C4.61 -0.66 8.88 -0.66 11.52 1.97C14.15 4.61 14.15 8.88 11.52 11.52ZM6.75 8.25C7.57 8.25 8.25 7.57 8.25 6.75C8.25 5.92 7.57 5.25 6.75 5.25C5.92 5.25 5.25 5.92 5.25 6.75C5.25 7.57 5.92 8.25 6.75 8.25Z"
                  fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
              </svg>

            </div>
            <div style="width: 18px;"></div>
            <p class="text-size-12" style=" color: white;">
              {{ $t('Footer.contact.address') }}</p>
          </div>
        </div>


      </div>
      <div class="div-spacer-h120"></div>
      <div v-show="isMobile" class="div-spacer-h120"></div>
      
    </div>

  </footer>
</template>


<script setup>
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
</script>

<style scoped>
.footer {
  background-color: #000000;
  /* 示例背景颜色 */
  color: white;
  
}
p{
  text-align: left;
}


.column {

  max-width: 300px;
  margin: 0 10px;
}

.separator {
  width: 0.5px;
  height: 227px;
  background: rgba(240, 240, 240, 0.5);
}

.separatorM {
  height: 0.5px;
  max-width: 300px;
  background: rgba(240, 240, 240, 0.5);
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .m-con{
    flex-direction: column !important;

  }

  .ms-con{
    justify-content: center !important;
    align-items: center !important;
  }

  .ms-con p{
    width: 1000px;
  }
  

  .column {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }
}
</style>
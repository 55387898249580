<template>
  <div class="language-switcher" v-show="currentLocale !== 'zh'">
    <button 
      @click="toggleLanguage" 
      class="lang-btn"
    >
      {{ currentLocale === 'zh' ? 'EN' : '中文' }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const { locale } = useI18n()
const currentLocale = computed(() => locale.value)

const toggleLanguage = () => {
  const newLocale = locale.value === 'zh' ? 'en' : 'zh'
  locale.value = newLocale
  localStorage.setItem('locale', newLocale)
}
</script>

<style scoped>
.language-switcher {
  margin: 0 20px;
  text-wrap: nowrap;
  max-width: 60px;
}

.lang-btn {
  padding: 4px 12px;
  border-radius: var(--cx-radius);
  border: 1px solid #000;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s;
}

.lang-btn:hover {
  background: #000;
  color: #fff;
}
</style>

<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 style="color: rgb(255, 255, 255); font-weight: bold; text-align: left;" class="text-size-72">
                    {{ $t('SolutionIndex.title') }}
                </h1>

                <div class="div-spacer-h16"></div>
                <p style="color: rgb(255, 255, 255); text-align: left;" class="text-size-24">
                    {{ $t('SolutionIndex.description') }}
                </p>


            </div>





        </div>

    </div>


    <div class="div-spacer-h288"></div>


    <div class="h-c">
        <div class="v-c" style="text-align: center;">
            <h3 class="text-size-64">{{ $t('SolutionIndex.solutionsTitle') }}</h3>

        </div>
    </div>


    <div class="div-spacer-h48"></div>

    <div class="h-c">

        <div class="v-c" style="gap: 16px;">

            <div class="g-c-2-1-1">


                <router-link to="/solution/smart-driving">
                    <SolutionsCard2 
                        :str1="$t('SolutionIndex.smartDriving.title')"
                        :str2="$t('SolutionIndex.smartDriving.desc')"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/自动驾驶汽车/车1.jpg')"
                        style="height: 425px;"
                    />
                </router-link>

                <router-link to="/solution/smart-surveying">
                    <SolutionsCard2 
                        :str1="$t('SolutionIndex.smartSurveying.title')"
                        :str2="$t('SolutionIndex.smartSurveying.desc')"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/测绘/测绘2.jpg')"
                        style="height: 425px;"
                    />
                </router-link>
                <router-link to="/solution/low-speed-vehicle">
                    <SolutionsCard2 
                        :str1="$t('SolutionIndex.lowSpeedVehicle.title')"
                        :str2="$t('SolutionIndex.lowSpeedVehicle.desc')"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/低速无人车/无人车2.jpg')"
                        style="height: 425px;"
                    />
                </router-link>

                <router-link to="/solution/smart-robot">
                    <SolutionsCard2 
                        :str1="$t('SolutionIndex.smartRobot.title')"
                        :str2="$t('SolutionIndex.smartRobot.desc')"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/智能机器人/机器人狗.jpg')"
                        style="height: 425px;"
                    />
                </router-link>
                <router-link to="/solution/agricultural-machinery">
                    <SolutionsCard2 
                        :str1="$t('SolutionIndex.agriculturalMachinery.title')"
                        :str2="$t('SolutionIndex.agriculturalMachinery.desc')"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/自动化农机/农机车.jpg')"
                        style="height: 425px;"
                    />
                </router-link>

                <router-link to="/solution/engineering-machinery">
                    <SolutionsCard2 
                        :str1="$t('SolutionIndex.engineeringMachinery.title')"
                        :str2="$t('SolutionIndex.engineeringMachinery.desc')"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/工程机械自动化/工程车2.jpg')"
                        style="height: 425px;"
                    />
                </router-link>
                <router-link to="/solution/uav">
                    <SolutionsCard2 
                        :str1="$t('SolutionIndex.uav.title')"
                        :str2="$t('SolutionIndex.uav.desc')"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/无人机/无人机方图2.jpg')"
                        style="height: 425px;"
                    />
                </router-link>

                <router-link to="/solution/port-shipping">
                    <SolutionsCard2 
                        :str1="$t('SolutionIndex.portShipping.title')"
                        :str2="$t('SolutionIndex.portShipping.desc')"
                        :src="require('@/assets/images/官网开发素材/行业解决方案/港口/港口2.jpg')"
                        style="height: 425px;"
                    />
                </router-link>
            </div>



        </div>


    </div>




<div class="div-spacer-h288"></div>

</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner-news-detail.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}

a{
    width: 100%;
}

</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import SolutionsCard2 from '@/components/SolutionsCard2.vue';







</script>
<template>
    <div class="PictureCard">
        <img :src="currentImage" alt="Photo" class="photo" />
    </div>
</template>

<script setup>
const props = defineProps({
    images: {
        type: Array,
        required: true,
        validator: (value) => value.length > 0
    },
    interval: {
        type: Number,
        default: 3000 // 默认3秒切换
    }
});

import { ref, onMounted, onUnmounted } from 'vue';

const currentIndex = ref(0);
const currentImage = ref(props.images[0]);
let timer = null;

const startCarousel = () => {
    timer = setInterval(() => {
        currentIndex.value = (currentIndex.value + 1) % props.images.length;
        currentImage.value = props.images[currentIndex.value];
    }, props.interval);
};

onMounted(() => {
    startCarousel();
});

onUnmounted(() => {
    if (timer) clearInterval(timer);
});
</script>

<style scoped>
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.PictureCard {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 0px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--cx-radius);
}
</style>
<template>
  <div class="cockpit-body" ref="cockpitRef">
    <div class="cockpit-image-container">
      <img 
        src="@/assets/images/官网开发素材/cockpit/image1.jpg" 
        alt="Image 1" 
        class="cockpit-image img1"
        :style="imageStyle"
      >
      <img 
        src="@/assets/images/官网开发素材/cockpit/image2.jpg" 
        alt="Image 2" 
        class="cockpit-image img2"
        :style="[imageStyle, { opacity: img2Opacity, pointerEvents: 'none' }]"
      >
      <img 
        src="@/assets/images/官网开发素材/cockpit/image3.jpg" 
        alt="Image 3" 
        class="cockpit-image img3"
        :style="[imageStyle, { 
          opacity: img3Opacity, 
          clipPath: img3ClipPath,
          pointerEvents: 'none' 
        }]"
      >
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const cockpitRef = ref(null)
const img2Opacity = ref(0)
const img3Opacity = ref(0)
const img3ClipPath = ref('inset(0 100% 0 0)')
let observer = null
let animationTimeout = null

const imageStyle = {
  borderRadius: '16px',
  transition: 'opacity 0.3s, clip-path 2.5s'
}

const playAnimation = () => {
  // 重置状态
  clearTimeout(animationTimeout)
  img2Opacity.value = 0
  img3Opacity.value = 0
  img3ClipPath.value = 'inset(0 100% 0 0)'
  
  // 开始动画序列
  img2Opacity.value = 1
  
  animationTimeout = setTimeout(() => {
    img2Opacity.value = 0
  }, 500)
  
  animationTimeout = setTimeout(() => {
    img2Opacity.value = 1
  }, 1000)
  
  animationTimeout = setTimeout(() => {
    img2Opacity.value = 0
    img3Opacity.value = 1
    img3ClipPath.value = 'inset(0 0 0 0)'
  }, 1500)
}

onMounted(() => {
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
          playAnimation()
        }
      })
    },
    {
      threshold: 1 // 当100%的元素可见时触发
    }
  )
  
  if (cockpitRef.value) {
    observer.observe(cockpitRef.value)
  }
})

onUnmounted(() => {
  if (observer) {
    observer.disconnect()
  }
  clearTimeout(animationTimeout)
})
</script>
  
  <style scoped>
  .cockpit-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block; /* 或者 block，取决于你的布局需求 */
  }
  
  .cockpit-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* 第一张图片需要设置为relative以占位 */
  .img1 {
    position: relative;
    z-index: 1;
  }
  
  /* 第二、三张图片重叠在第一张上面 */
  .img2, .img3 {
    z-index: 2;
  }
  
  /* 其他样式保持不变 */
  .page-product-cockpit .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .page-product-cockpit .banner-content {
    padding-top: 100px;
  }
  
  .page-product-cockpit .banner-content h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .page-product-cockpit .banner-content small {
    position: absolute;
    width: 935px;
    height: 64px;
    top: 524px;
    display: block;
    font-size: 72px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .page-product-cockpit .banner-content strong {
    display: block;
    font-size: 72px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .page-product-cockpit .banner-content p {
    position: absolute;
    width: 935px;
    height: 64px;
    top: 624px;
    color: #FFF;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
  
  .page-product-cockpit .photos-3 {
    width: 1060px;
  }
  
  .page-product-cockpit .photos-3 li,
  .page-product-cockpit .photos-3 img {
    width: 340px;
  }
  </style>
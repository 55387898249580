import { onMounted, onBeforeUnmount } from 'vue'

export function useScrollAnimation() {
  let observer = null;

  const observeElements = () => {
    observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        // 获取元素的可见高度（像素）
        const visibleHeight = entry.intersectionRect.height;
        // 获取元素的总高度
        const totalHeight = entry.boundingClientRect.height;
        // 计算可见比例
        const visibleRatio = visibleHeight / totalHeight;

        // 如果可见比例超过 30% 或可见高度超过 24px，则添加动画类
        if (visibleRatio >= 0.3 || visibleHeight >= 50) {
          entry.target.classList.add('appear');
          observer.unobserve(entry.target);
        }
      })
    }, {
      // 设置多个阈值以便更精确地计算可见高度
      threshold: [0, 0.1, 0.2, 0.3, 0.4],
      // 保留一定的边距以提前触发检测
      rootMargin: '50px'
    });

    document.querySelectorAll('.h-c').forEach(el => {
      observer.observe(el);
    });
  }

  onMounted(() => {
    observeElements();
  });

  onBeforeUnmount(() => {
    if (observer) {
      document.querySelectorAll('.h-c').forEach(el => {
        observer.unobserve(el);
      });
      observer.disconnect();
    }
  });
}
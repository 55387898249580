<template>
    <div class="ProductCard" :style="containerStyle" style="border-radius: var(--cx-radius);">
        <p style="color: rgb(0, 0, 0);
            
            text-align: center;
            font-weight: bold;
            
            " class="text-size-24">{{ str1 }}</p>
        <p style="color: rgb(0, 0, 0);
        text-align: center;
            " class="text-size-20">{{ str2 }}</p>
        <p style="color: rgb(0, 0, 0);
            
            
            
            text-align: center;
            margin-top: 10px;
            text-decoration-line: underline;" class="text-size-10">了解更多 ></p>






    </div>

</template>

<script setup>
// 接收图片URL和圆角值作为props
const props = defineProps({
    src: {
        type: String,
        required: true,
    },
    str1: {
        type: String,
        required: true,
    },
    str2: {
        type: String,
        required: true,
    },
    
});

// 计算容器样式
const containerStyle = {
    backgroundImage: `url(${props.src})`
};
</script>

<style scoped>
.ProductCard {
    padding: 24px;
    height: 100%;

    
    aspect-ratio: 4/3;   /* 设置宽高比，可以根据需要调整 */

    

    background-size: cover;
    background-position: center;

    
    /* 矩形边框 */

    /* box-shadow: 0px 5px 27px 2px rgba(50, 50, 93, 0.05); */
}
</style>
<template>
  <div class="ProductCompositionCard" :style="{ transform: `scale(${scale})` }" @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave">
    <div class="product-image" >
      
      <PictureCard :src="image" />
    </div>
    <div class="product-info">
      <h2 
      style="color: rgb(0, 0, 0);
      
      
      
      text-align: center;
      text-transform: uppercase;" class="text-size-24">

        {{ title }}

      </h2>
      <p
      style="color: rgb(0, 0, 0);
      
      
      
      text-align: center;" class="text-size-12">

        {{ description }}

      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import PictureCard from './PictureCard.vue';

// 定义props
const props = defineProps({
  image: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  }
});

// 定义响应式变量用于缩放
const scale = ref(1);

// 鼠标进入事件处理器
const onMouseEnter = () => {
  scale.value = 1.05; // 放大比例
};

// 鼠标离开事件处理器
const onMouseLeave = () => {
  scale.value = 1; // 恢复原始大小
};
</script>

<style scoped>
.ProductCompositionCard {
  margin-top: 16px;
  margin-bottom: 16px;
  display: grid;
  grid-template-rows: 204px 137px;
  
  width: 100%;
  height: 100%;

  
  
  overflow: hidden;
  transition: all 0.3s ease-in-out; /* 添加过渡效果 */
  
  
  border-radius: var(--cx-radius);

  background: rgb(255, 255, 255);
  padding-bottom: 25px;
}

.product-image {
  object-fit: cover;
  object-position: center;
  height: auto;
  overflow: hidden;
  
  
  

  
}

.product-info {
  gap: 12px;
  margin-top: 20px;
  padding-left: 14px;
  padding-right: 14px;
  
  
  box-sizing: border-box;
  text-align: center;
  height: 137px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
</style>
<!-- 产品服务-算法服务 -->

<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 935px;">
                <h1 class="text-size-72" style="color: rgb(0, 0, 0);color: rgb(255, 255, 255);text-align: left;">
                    {{ $t('SmartCockpit.title1') }}<br>
                    {{ $t('SmartCockpit.title2') }}
                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(0, 0, 0);color: rgb(255, 255, 255);text-align: left;">
                    {{ $t('SmartCockpit.description') }}
                </p>


            </div>





        </div>

    </div>


    <div class="div-spacer-h288"></div>


    <div class="h-c">

        <div class="v-c" style="flex-direction: column;">

            <!-- <img src="@/assets/images/官网开发素材/cockpit/连屏.png" alt=""> -->
            <img src="@/assets/images/官网开发素材/cockpit/cx-c9h.png" alt="">
            


        </div>

    </div>




    <div class="h-c">
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);text-align: center;">
                    {{ $t('SmartCockpit.model1') }}<br>
                    {{ $t('SmartCockpit.model2') }}
                </h3>


            </div>

        </div>

    </div>

    <div class="div-spacer-h288"></div>



    <div class="h-c">

        <div class="v-c" style="text-align: center;">
            <h3 class="text-size-64">{{ $t('SmartCockpit.advantageTitle') }}</h3>
        </div>



    </div>


    <div class="div-spacer-h80"></div>





    <div class="h-c">
        <div class="v-c">

            <div class="g-c-2-1-1">
                <AdvantageCard 
                    :title="$t('SmartCockpit.advantages.first.title')" 
                    :describe="$t('SmartCockpit.advantages.first.description')"
                    :src="require('@/assets/images/官网开发素材/cockpit/i1.svg')" />
                <AdvantageCard 
                    :title="$t('SmartCockpit.advantages.second.title')" 
                    :describe="$t('SmartCockpit.advantages.second.description')"
                    :src="require('@/assets/images/官网开发素材/cockpit/i2.svg')" />
                <AdvantageCard 
                    :title="$t('SmartCockpit.advantages.third.title')" 
                    :describe="$t('SmartCockpit.advantages.third.description')"
                    :src="require('@/assets/images/官网开发素材/cockpit/i3.svg')" />
                <AdvantageCard 
                    :title="$t('SmartCockpit.advantages.fourth.title')" 
                    :describe="$t('SmartCockpit.advantages.fourth.description')"
                    :src="require('@/assets/images/官网开发素材/cockpit/i4.svg')" />

            </div>

        </div>

    </div>

    <div class="div-spacer-h288"></div>



    <div class="h-c">

        <div class="v-c" style="text-align: center;">
            <h3 class="text-size-64">{{ $t('SmartCockpit.experienceTitle') }}</h3>
        </div>
        <div class="div-spacer-h16"></div>
        <div class="v-c" style="text-align: center;">
            <p class="text-size-20">{{ $t('SmartCockpit.experienceDesc') }}</p>
        </div>


    </div>
    <div class="div-spacer-h48"></div>

    <div class="h-c">

        <div class="v-c" style="text-align: center;">
            <Cockpit />
        </div>

    </div>

    <div class="div-spacer-h288"></div>

</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner-product-cockpit.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import AdvantageCard from '@/components/AdvantageCard.vue';
import Cockpit from '@/components/Cockpit.vue';
const items = [
    { image: require('@/assets/images/官网开发素材/车规产品/imubox蓝色背景.jpg'), title: 'Title 1', description: 'Description 1' },
    { image: require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=成本低.svg'), title: 'Title 2', description: 'Description 2' },
    { image: require('@/assets/images/官网开发素材/行业解决方案/自动驾驶汽车/车1.jpg'), title: 'Title 3', description: 'Description 3' },
];

</script>
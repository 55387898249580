/* 2产品服务-卫惯系列/车规/cpfusion */


<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

                <p class="text-size-64" style="color: rgb(0, 0, 0); font-weight: bold; text-wrap: nowrap; text-align: left;">
                    {{ $t('CxpFusion.banner.title1') }}<br>
                    {{ $t('CxpFusion.banner.title2') }}
                </p>


                <div class="div-spacer-h48"></div>


                <h1 class="text-size-36" style="color: rgb(0, 0, 0); font-weight:400; text-wrap: nowrap; text-align: left;">
                    {{ $t('CxpFusion.banner.subtitle1') }}
                </h1>
                <h1 class="text-size-36" style="color: rgb(0, 0, 0); font-weight: bold; text-wrap: nowrap; text-align: left;">
                    {{ $t('CxpFusion.banner.subtitle2') }}
                </h1>
                <div class="div-spacer-h48"></div>

                <p v-show="!isMobile" class="text-size-16" style="color: rgb(89, 89, 89); text-align: left;">
                    {{ $t('CxpFusion.banner.description') }}
                </p>


            </div>






        </div>

    </div>

    <div v-show="isMobile" class="h-c">
        <div class="div-spacer-h288"></div>
        <div class="v-c">

            <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                {{ $t('CxpFusion.banner.description') }}
            </p>
        </div>
        <div class="div-spacer-h288"></div>
    </div>
    <div v-show="!isMobile" class="div-spacer-h288"></div>






    <div class="h-c">

        <GroupNameCard :title="$t('CxpFusion.advantages.title')" :describe="$t('CxpFusion.advantages.subtitle')" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/cxpfusion1.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: left;">
                    {{ $t('CxpFusion.product.composition.title') }}
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1); text-align: left; list-style-position: inside;">
                    <li v-for="(item, key) in $tm('CxpFusion.product.composition.items')" :key="key">
                        {{ item }}
                    </li>
                </ul>

            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/cxpfusion2.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: left;">
                    {{ $t('CxpFusion.product.features.title') }}
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1); text-align: left; list-style-position: inside;">
                    <li v-for="(item, index) in $tm('CxpFusion.product.features.items')" :key="index">
                        {{ item }}
                    </li>
                </ul>


            </div>


        </div>
    </div>



    <div class="div-spacer-h288"></div>


    <div class="h-c">

        <GroupNameCard :title="$t('CxpFusion.advantages.title')" :describe="$t('CxpFusion.advantages.subtitle')" />

    </div>


    <div class="div-spacer-h48"></div>





    <div class="h-c">
        <div class="v-c">
            <div class="g-c-2-1-1" style="gap: 40px;">
                <AdvantageCard2 :title="$t('CxpFusion.advantages.items.cost.title')" :describe="$t('CxpFusion.advantages.items.cost.desc')" :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=成本低.svg')" />
                <AdvantageCard2 :title="$t('CxpFusion.advantages.items.size.title')" :describe="$t('CxpFusion.advantages.items.size.desc')" :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=体积更小.svg')" />
                <AdvantageCard2 :title="$t('CxpFusion.advantages.items.form.title')" :describe="$t('CxpFusion.advantages.items.form.desc')" :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=形态更活.svg')" />
                <AdvantageCard2 :title="$t('CxpFusion.advantages.items.performance.title')" :describe="$t('CxpFusion.advantages.items.performance.desc')" :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=性能更强.svg')" />
                <AdvantageCard2 :title="$t('CxpFusion.advantages.items.accuracy.title')" :describe="$t('CxpFusion.advantages.items.accuracy.desc')" :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=精度更高.svg')" />
                <AdvantageCard2 :title="$t('CxpFusion.advantages.items.safety.title')" :describe="$t('CxpFusion.advantages.items.safety.desc')" :src="require('@/assets/images/官网开发素材/车规产品/cxfusion/icon_技术优势,属性 1=安全.svg')" />


            </div>

        </div>
    </div>
    <div class="div-spacer-h288"></div>
    <div class="h-c">
        <div class="v-c">
            <h3 class="text-size-64" style="color: rgb(0, 0, 0); font-weight: bold; text-align: center;">
                {{ $t('CxpFusion.specs.title') }}
            </h3>
        </div>
    </div>
    <div class="div-spacer-h48"></div>

    <div class="h-c">
        <div class="v-c">
            <SpecTable :specData="[
                {
                    key: $t('CxpFusion.specs.sections.device.title'),
                    tableData: [
                        {
                            title: $t('CxpFusion.specs.sections.device.interface.title'),
                            contentList: $tm('CxpFusion.specs.sections.device.interface.items')
                        },
                        {
                            title: $t('CxpFusion.specs.sections.device.sleepMode.title'),
                            contentList: [$t('CxpFusion.specs.sections.device.sleepMode.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.device.voltage.title'),
                            contentList: [$t('CxpFusion.specs.sections.device.voltage.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.device.current.title'),
                            contentList: [$t('CxpFusion.specs.sections.device.current.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.device.staticCurrent.title'),
                            contentList: [$t('CxpFusion.specs.sections.device.staticCurrent.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.device.workingTemp.title'),
                            contentList: [$t('CxpFusion.specs.sections.device.workingTemp.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.device.size.title'),
                            contentList: [$t('CxpFusion.specs.sections.device.size.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.device.weight.title'),
                            contentList: [$t('CxpFusion.specs.sections.device.weight.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.device.protection.title'),
                            contentList: [$t('CxpFusion.specs.sections.device.protection.value')]
                        }
                    ]
                },
                {
                    key: $t('CxpFusion.specs.sections.antenna.title'),
                    tableData: [
                        {
                            title: $t('CxpFusion.specs.sections.antenna.frequency.title'),
                            contentList: $tm('CxpFusion.specs.sections.antenna.frequency.items')
                        },
                        {
                            title: $t('CxpFusion.specs.sections.antenna.axialRatio.title'),
                            contentList: $tm('CxpFusion.specs.sections.antenna.axialRatio.items')
                        },
                        {
                            title: $t('CxpFusion.specs.sections.antenna.gain.title'),
                            contentList: $tm('CxpFusion.specs.sections.antenna.gain.items')
                        },
                        {
                            title: $t('CxpFusion.specs.sections.antenna.phaseCenter.title'),
                            contentList: [$t('CxpFusion.specs.sections.antenna.phaseCenter.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.antenna.noiseFigure.title'),
                            contentList: [$t('CxpFusion.specs.sections.antenna.noiseFigure.value')]
                        }
                    ]
                },
                {
                    key: $t('CxpFusion.specs.sections.gnss.title'),
                    tableData: [
                        {
                            title: $t('CxpFusion.specs.sections.gnss.tiff.title'),
                            contentList: $tm('CxpFusion.specs.sections.gnss.tiff.items')
                        },
                        {
                            title: $t('CxpFusion.specs.sections.gnss.horizontalAccuracy.title'),
                            contentList: $tm('CxpFusion.specs.sections.gnss.horizontalAccuracy.items')
                        },
                        {
                            title: $t('CxpFusion.specs.sections.gnss.velocityAccuracy.title'),
                            contentList: [$t('CxpFusion.specs.sections.gnss.velocityAccuracy.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.gnss.ppsAccuracy.title'),
                            contentList: [$t('CxpFusion.specs.sections.gnss.ppsAccuracy.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.gnss.updateRate.title'),
                            contentList: [$t('CxpFusion.specs.sections.gnss.updateRate.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.gnss.dataFormat.title'),
                            contentList: $tm('CxpFusion.specs.sections.gnss.dataFormat.items')
                        }
                    ]
                },
                {
                    key: $t('CxpFusion.specs.sections.imu.title'),
                    tableData: [
                        {
                            title: $t('CxpFusion.specs.sections.imu.range.title'),
                            contentList: $tm('CxpFusion.specs.sections.imu.range.items')
                        },
                        {
                            title: $t('CxpFusion.specs.sections.imu.bias.title'),
                            contentList: $tm('CxpFusion.specs.sections.imu.bias.items')
                        },
                        {
                            title: $t('CxpFusion.specs.sections.imu.arw.title'),
                            contentList: $tm('CxpFusion.specs.sections.imu.arw.items')
                        },
                        {
                            title: $t('CxpFusion.specs.sections.imu.vrw.title'),
                            contentList: $tm('CxpFusion.specs.sections.imu.vrw.items')
                        }
                    ]
                },
                {
                    key: $t('CxpFusion.specs.sections.deadReckoning.title'),
                    tableData: [
                        {
                            title: $t('CxpFusion.specs.sections.deadReckoning.position.title'),
                            contentList: [$t('CxpFusion.specs.sections.deadReckoning.position.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.deadReckoning.heading.title'),
                            contentList: [$t('CxpFusion.specs.sections.deadReckoning.heading.value')]
                        },
                        {
                            title: $t('CxpFusion.specs.sections.deadReckoning.velocity.title'),
                            contentList: [$t('CxpFusion.specs.sections.deadReckoning.velocity.value')]
                        }
                    ]
                }
            ]" />
        </div>
    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c">
        <router-link to="/about/contact" style="text-align: center; width: 100%;">
            <AdapButtomBlack :text="$t('CxpFusion.cta.button')" />
        </router-link>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0); font-weight: bold; text-align: center;">
            {{ $t('CxpFusion.applications.title') }}
        </h3>


    </div>
    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div v-show="!isMobile" class="v-c">
            <ScrollingCards2 :items="ScrollingCards2_items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards2 :items="ScrollingCards2_items" />
        </div>
    </div>





    <div class="div-spacer-h288"></div>






</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/车规产品/cxfusion/banner.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}








@media (max-width: 768px) {
    
}
</style>



<script setup>

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()

import SpecTable from '@/components/SpecTable.vue';
import GroupNameCard from '@/components/GroupNameCard.vue';
import PictureCard from '@/components/PictureCard.vue';
import AdvantageCard2 from '@/components/AdvantageCard2.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';


import ScrollingCards2 from '@/components/ScrollingCards2.vue';
import mScrollingCards2 from '@/components/mScrollingCards2.vue';
import { computed } from 'vue'
const ScrollingCards2_items = computed(() => [
    { 
        image: require('@/assets/images/官网开发素材/inertia/p2.jpg'), 
        title: t('CxpFusion.applications.scenes.tunnel.title'), 
        desc: t('CxpFusion.applications.scenes.tunnel.desc') 
    },
    { 
        image: require('@/assets/images/官网开发素材/inertia/p3.jpg'), 
        title: t('CxpFusion.applications.scenes.bridge.title'), 
        desc: t('CxpFusion.applications.scenes.bridge.desc') 
    },
    { 
        image: require('@/assets/images/官网开发素材/inertia/p4.jpg'), 
        title: t('CxpFusion.applications.scenes.parking.title'), 
        desc: t('CxpFusion.applications.scenes.parking.desc') 
    },
    { 
        image: require('@/assets/images/官网开发素材/inertia/p6.jpg'), 
        title: t('CxpFusion.applications.scenes.canyon.title'), 
        desc: t('CxpFusion.applications.scenes.canyon.desc') 
    },
    { 
        image: require('@/assets/images/官网开发素材/inertia/p5.jpg'), 
        title: t('CxpFusion.applications.scenes.complex.title'), 
        desc: t('CxpFusion.applications.scenes.complex.desc') 
    }
]);


</script>
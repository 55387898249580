import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'

// 引入全局样式 解决 1px 问题
import './assets/global.css'

createApp(App).use(store).use(router).use(i18n).mount('#app')


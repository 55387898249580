<template>
  <div class="h-c banner" style="justify-content: flex-end;">
    <div class="v-c">
      <div style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">
        <p class="text-size-64" style="color: rgb(0, 0, 0);text-align: left;">
          {{ $t('IB11.title') }}
        </p>

        <h1 class="text-size-96" style="color: rgb(0, 0, 0);font-weight: bold;text-wrap: nowrap;text-align: left;">
          {{ $t('IB11.model') }}
        </h1>

        <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1);text-align: left;">
          {{ $t('IB11.description') }}
        </p>
      </div>
    </div>
  </div>

  <div v-show="isMobile" class="h-c">
    <div class="div-spacer-h288"></div>
    <div class="v-c">
      <p class="text-size-16" style="color: rgba(89, 89, 89,1);text-align: left;">
        {{ $t('IB11.description') }}
      </p>
    </div>
    <div class="div-spacer-h288"></div>
  </div>

  <div v-show="!isMobile" class="div-spacer-h288"></div>

  <div class="h-c">
    <CombinedTitle title="IMU-BOX" shortTitle="" subTitle="IMU BOX" />
  </div>
  <div class="div-spacer-h48"></div>

  <div class="h-c">
    <div class="v-c">
      <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/整机/IB11/IB11-1.jpg')" :radius="28"
        style="max-width: 621px;height: 351px;" />
      <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
        <h3 class="text-size-48" style="color: rgb(0, 0, 0);text-align: left;">
          {{ $t('IB11.composition.title') }}
        </h3>
        <ul class="text-size-16" style="color: rgba(89, 89, 89,1);font-weight: bold;text-align: left;">
          <li>{{ $t('IB11.composition.items.accelerometer') }}</li>
          <li>{{ $t('IB11.composition.items.gyroscope') }}</li>
          <li>{{ $t('IB11.composition.items.arrayIMU') }}</li>
        </ul>
        <p class="text-size-16" style="color: rgba(89, 89, 89,1);font-weight: bold;text-align: left;">
          {{ $t('IB11.composition.description') }}
        </p>
      </div>
    </div>
  </div>

  <div class="div-spacer-h80"></div>

  <div class="h-c">
    <div class="v-c">
      <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/整机/IB11/IB11-2.jpg')" :radius="28"
        style="max-width: 621px;height: 351px;" />
      <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
        <h3 class="text-size-48" style="color: rgb(0, 0, 0);text-align: left;">
          {{ $t('IB11.features.title') }}
        </h3>
        <p class="text-size-16" style="color: rgba(89, 89, 89,1);font-weight: bold;text-align: left;">
          {{ $t('IB11.features.description') }}
        </p>
        <ul class="text-size-16" style="color: rgba(89, 89, 89,1);font-weight: bold;text-align: left;">
          <li v-for="(item, index) in $tm('IB11.features.items')" :key="index">{{ item }}</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="div-spacer-h288"></div>

  <div class="h-c">
    <p class="text-size-64" style="text-align: center;color: rgb(0, 0, 0);font-weight: bold;text-align: center;">
      {{ $t('IB11.specifications.title') }}
    </p>
  </div>

  <div class="div-spacer-h80"></div>

  <div class="h-c">
    <div class="v-c">
      <SpecTable :specData="[
        {
          key: $t('IB11.specifications.physical.title'),
          tableData: [
            {
              title: $t('IB11.specifications.physical.size.title'),
              contentList: [$t('IB11.specifications.physical.size.value')]
            },
            {
              title: $t('IB11.specifications.physical.weight.title'),
              contentList: [$t('IB11.specifications.physical.weight.value')]
            },
            {
              title: $t('IB11.specifications.physical.voltage.title'),
              contentList: [$t('IB11.specifications.physical.voltage.value')]
            },
            {
              title: $t('IB11.specifications.physical.power.title'),
              contentList: [$t('IB11.specifications.physical.power.value')]
            },
            {
              title: $t('IB11.specifications.physical.connector.title'),
              contentList: [$t('IB11.specifications.physical.connector.value')]
            }
          ]
        },
        {
          key: $t('IB11.specifications.environment.title'),
          tableData: [
            {
              title: $t('IB11.specifications.environment.workingTemp.title'),
              contentList: [$t('IB11.specifications.environment.workingTemp.value')]
            },
            {
              title: $t('IB11.specifications.environment.storageTemp.title'),
              contentList: [$t('IB11.specifications.environment.storageTemp.value')]
            },
            {
              title: $t('IB11.specifications.environment.humidity.title'),
              contentList: [$t('IB11.specifications.environment.humidity.value')]
            },
            {
              title: $t('IB11.specifications.environment.protection.title'),
              contentList: [$t('IB11.specifications.environment.protection.value')]
            },
            {
              title: $t('IB11.specifications.environment.vibration.title'),
              contentList: [$t('IB11.specifications.environment.vibration.value')]
            }
          ]
        },
        {
          key: $t('IB11.specifications.imu.title'),
          tableData: [
            {
              title: $t('IB11.specifications.imu.range.title'),
              contentList: [
                $t('IB11.specifications.imu.range.gyro'),
                $t('IB11.specifications.imu.range.accelerometer')
              ]
            },
            {
              title: $t('IB11.specifications.imu.bias.title'),
              contentList: [
                $t('IB11.specifications.imu.bias.gyro'),
                $t('IB11.specifications.imu.bias.accelerometer')
              ]
            },
            {
              title: $t('IB11.specifications.imu.arw.title'),
              contentList: [
                $t('IB11.specifications.imu.arw.gyro'),
                $t('IB11.specifications.imu.arw.accelerometer')
              ]
            },
            {
              title: $t('IB11.specifications.imu.vrw.title'),
              contentList: [
                $t('IB11.specifications.imu.vrw.gyro'),
                $t('IB11.specifications.imu.vrw.accelerometer')
              ]
            }
          ]
        }
      ]" />
    </div>
  </div>

  <div class="div-spacer-h80"></div>

  <div class="h-c">
    <div class="v-c" style="justify-content: center;">
      <router-link to="/about/contact" style="text-align: center; width: 100%;">
        <AdapButtomBlack :text="$t('IB11.getMoreInfo')" />
      </router-link>
    </div>
  </div>

  <div class="div-spacer-h288"></div>

  <div class="h-c">
    <h3 class="text-size-64" style="color: rgb(0, 0, 0);font-weight: bold;text-align: center;">
      {{ $t('IB11.applications.title') }}
    </h3>
  </div>

  <div class="div-spacer-h80"></div>

  <div class="h-c">
    <div v-show="!isMobile" class="v-c">
      <ScrollingCards2 :items="ScrollingCards2_items" />
    </div>

    <div v-show="isMobile" class="v-c">
      <mScrollingCards2 :items="ScrollingCards2_items" />
    </div>
  </div>

  <div class="div-spacer-h288"></div>
</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner车规产品/科技正圆+imubox.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards2 from '@/components/ScrollingCards2.vue';
import mScrollingCards2 from '@/components/mScrollingCards2.vue';
const ScrollingCards2_items = [
    { image: require('@/assets/images/官网开发素材/inertia/p2.jpg'), title: '隧道内岔路口', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p3.jpg'), title: '高架桥下', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p4.jpg'), title: '地下停车场', desc: '无卫星信号' },
    { image: require('@/assets/images/官网开发素材/inertia/p6.jpg'), title: '城市峡谷', desc: '卫星信号弱，多路劲效应影响' },
    { image: require('@/assets/images/官网开发素材/inertia/p5.jpg'), title: '复杂立体交通', desc: '卫星信号无/弱' }
];
import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import FeatureCard from '@/components/FeatureCard.vue';
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtom from '@/components/AdapButtom.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>
<template>



  <div class="h-c banner" style="justify-content: flex-end;">

    <div class="v-c">
      <div
        style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

        <p class="text-size-64" style="color: rgb(0, 0, 0);
                    
                    

                    
                    
                    text-align: left;">
          {{ $t('CXGYB130.title') }}
        </p>

        <h1 class="text-size-96" style="color: rgb(0, 0, 0);
                    
                    font-weight: bold;
                    text-wrap: nowrap;
                    text-align: left;">
          CXGY-B130
        </h1>


        <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
          {{ $t('CXGYB130.description') }}
        </p>


      </div>






    </div>

  </div>


  <div v-show="isMobile" class="h-c">
    <div class="div-spacer-h288"></div>
    <div class="v-c">

      <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
        {{ $t('CXGYB130.description') }}
      </p>
    </div>
    <div class="div-spacer-h288"></div>
  </div>
  <div v-show="!isMobile" class="div-spacer-h288"></div>





  <div class="h-c">

    <CombinedTitle :title="$t('CXGYB130.title')" shortTitle="" subTitle="CXGY-B130" />

  </div>
  <div class="div-spacer-h48"></div>


  <div class="h-c">
    <div class="v-c">

      <PictureCard :src="require('@/assets/images/官网开发素材/工规产品/CXBSD131SA/单北斗.3.jpg')" :radius="28"
        style="max-width: 621px;height: 351px;" />
      <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
        <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                
                    



                    
                    
                    text-align: left;">
          {{ $t('CXGYB130.components.title') }}
        </h3>
        <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">




          <li v-for="(item, index) in $tm('CXGYB130.components.list')" :key="index">{{ item }}</li>
        </ul>

      </div>


    </div>
  </div>

  <div class="div-spacer-h80"></div>

  <div class="h-c">
    <div class="v-c">

      <PictureCard :src="require('@/assets/images/官网开发素材/工规产品/CXBSD131SA/单北斗.4.jpg')" :radius="28"
        style="max-width: 621px;height: 351px;" />
      <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
        <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
          {{ $t('CXGYB130.features.title') }}
        </h3>


        <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    font-weight: bold;
                    
                    


                    
                    text-align: left;">




          <li v-for="(item, index) in $tm('CXGYB130.features.list')" :key="index">{{ item }}</li>
        </ul>



      </div>


    </div>
  </div>
  <div class="div-spacer-h288"></div>

  <div class="h-c">
    <p class="text-size-64" style="text-align: center;
            color: rgb(0, 0, 0);
            
            
            font-weight: bold;

            
            text-align: center;">
      {{ $t('CXGYB130.parameters.title') }}
    </p>

  </div>

  <div class="div-spacer-h80"></div>




  <div class="h-c">
    <div class="v-c">

      <SpecTable :specData="$tm('CXGYB130.specData')" />

    </div>
  </div>


  <div class="div-spacer-h80"></div>

  <div class="h-c">
    <div class="v-c" style="justify-content: center;">
      <router-link to="/about/contact" style="text-align: center; width: 100%;">
        <AdapButtomBlack :text="$t('CXGYB130.contact.button')" />
      </router-link>
    </div>
  </div>

  <div class="div-spacer-h288"></div>

  <div class="h-c">
    <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            font-weight: bold;
            text-align: center;">
      {{ $t('CXGYB130.scenarios.title') }}
    </h3>


  </div>
  <div class="div-spacer-h80"></div>


  <div class="h-c">

    <div v-show="!isMobile" class="v-c">
      <ScrollingCards :items="items" />
    </div>

    <div v-show="isMobile" class="v-c">
      <mScrollingCards :items="items" />
    </div>





  </div>


  <div class="div-spacer-h288"></div>





</template>



<style scoped>
.banner {

  background-image: url("@/assets/images/官网开发素材/banner/banner工规产品/科技正圆+CXBSD131SA.jpg");

  background-size: cover;
  /* 使背景图片覆盖整个 div */
  background-position: center;
  /* 使背景图片居中 */
  background-repeat: no-repeat;
  /* 防止背景图片重复 */


}
</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards from '@/components/ScrollingCards.vue';
import mScrollingCards from '@/components/mScrollingCards.vue';
const items = [
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/无人车4.jpg'), title: '低速无人车', url: '/solution/low-speed-vehicle' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/机器人.jpg'), title: '智能机器人', url: '/solution/smart-robot' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/矿区2.jpg'), title: '工程机械自动化', url: '/solution/engineering-machinery' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/智慧农机.jpg'), title: '自动化农机', url: '/solution/agricultural-machinery' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/无人机3.jpg'), title: '无人飞行器', url: '/solution/uav' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/港口.jpg'), title: '港口船舶', url: '/solution/port-shipping' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/自动驾驶.jpg'), title: '智能驾驶汽车', url: '/solution/smart-driving' },
  { image: require('@/assets/images/官网开发素材/banner/行业解决方案banner/测绘1.jpg'), title: '智慧测绘仪器', url: '/solution/smart-surveying' },

]; import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import FeatureCard from '@/components/FeatureCard.vue';
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtom from '@/components/AdapButtom.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>
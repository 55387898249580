<template>



    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">

                <p class="text-size-64" style="color: rgb(0, 0, 0);
                    
                    
                    font-weight: bold;
                    
                    
                    text-align: left;">
                    {{ $t('IM12.title') }}
                </p>

                <h1 class="text-size-96" style="color: rgb(0, 0, 0);
                    
                    font-weight: bold;
                    text-wrap: nowrap;
                    text-align: left;">
                    {{ $t('IM12.model') }}
                </h1>


                <p v-show="!isMobile" class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                    {{ $t('IM12.description') }}
                </p>


            </div>






        </div>

    </div>


    <div v-show="isMobile" class="h-c">
        <div class="div-spacer-h288"></div>
        <div class="v-c">

            <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    text-align: left;">
                {{ $t('IM12.description') }}
            </p>
        </div>
        <div class="div-spacer-h288"></div>
    </div>
    <div v-show="!isMobile" class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="text-align: center;color: rgb(0, 0, 0);
            
            
            
            
            
            text-align: center;">
            {{ $t('IM12.selectProduct') }}
        </h3>
    </div>
    <div class="div-spacer-h60"></div>

    <div class="h-c">
        <div class="v-c dfsghtr5" style="max-width: 750px">
            <router-link to="/product/automotive/imu/im11">
                <AdapButtom text='IM11' />
            </router-link>
            <router-link to="/product/automotive/imu/im12">
                <AdapButtom text='IM12' />
            </router-link>
            <router-link to="/product/automotive/imu/im13">
                <AdapButtom text='IM13' />
            </router-link>
            <router-link to="/product/automotive/imu/im15">
                <AdapButtom text='IM15(板对板)' />
            </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">

        <CombinedTitle title="IM12 / IM12-M" shortTitle="（MCU）" subTitle="IMU 12 / IM12-MCU" />

    </div>
    <div class="div-spacer-h48"></div>


    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/模组/IM12/IM12-1.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    {{ $t('IM12.composition.title') }}
                </h3>
                <ul class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    font-weight: bold;
                    
                    text-align: left;">

                    <li>{{ $t('IM12.composition.items.accelerometer') }}</li>
                    <li>{{ $t('IM12.composition.items.gyroscope') }}</li>
                    <li>{{ $t('IM12.composition.items.arrayIMU') }}</li>
                </ul>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    font-weight: bold;
                    
                    text-align: left;">
                    {{ $t('IM12.composition.description') }}
                </p>
            </div>


        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c">

            <PictureCard :src="require('@/assets/images/官网开发素材/车规产品/模组/IM12/IM12-2.jpg')" :radius="28"
                style="max-width: 621px;height: 351px;" />
            <div style="max-width: 460px;gap: 20px;display: flex;flex-direction: column">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0);
                    
                    
                    
                    
                    
                    text-align: left;">
                    {{ $t('IM12.features.title') }}
                </h3>
                <p class="text-size-16" style="color: rgba(89, 89, 89,1);
                    
                    
                    
                    
                    font-weight: bold;
                    text-align: left;">
                    {{ $t('IM12.features.description') }}
                </p>
                <div style="display: grid;grid-template-columns: 1fr 1fr;gap: 16px;">


                    <FeatureCard 
                        :title="$t('IM12.features.cards.compatibility.title')" 
                        :describe="$t('IM12.features.cards.compatibility.desc')" />
                    <FeatureCard 
                        :title="$t('IM12.features.cards.adaptability.title')" 
                        :describe="$t('IM12.features.cards.adaptability.desc')" />
                    <FeatureCard 
                        :title="$t('IM12.features.cards.miniaturization.title')" 
                        :describe="$t('IM12.features.cards.miniaturization.desc')" />
                    <FeatureCard 
                        :title="$t('IM12.features.cards.performance.title')" 
                        :describe="$t('IM12.features.cards.performance.desc')" />

                </div>


            </div>


        </div>
    </div>
    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <p class="text-size-64" style="text-align: center;
            color: rgb(0, 0, 0);
            
            
            
            font-weight: bold;
            
            text-align: center;">
            {{ $t('IM12.specifications.title') }}
        </p>

    </div>

    <div class="div-spacer-h80"></div>




    <div class="h-c">
        <div class="v-c">

            <SpecTable :specData="[
                {
                    key: $t('IM12.specifications.physical.title'),
                    tableData: [
                        {
                            title: $t('IM12.specifications.physical.size.title'),
                            contentList: [$t('IM12.specifications.physical.size.value')]
                        },
                        {
                            title: $t('IM12.specifications.physical.weight.title'),
                            contentList: [$t('IM12.specifications.physical.weight.value')]
                        },
                        {
                            title: $t('IM12.specifications.physical.voltage.title'),
                            contentList: [$t('IM12.specifications.physical.voltage.value')]
                        },
                        {
                            title: $t('IM12.specifications.physical.power.title'),
                            contentList: [$t('IM12.specifications.physical.power.value')]
                        },
                        {
                            title: $t('IM12.specifications.physical.connector.title'),
                            contentList: [$t('IM12.specifications.physical.connector.value')]
                        },
                        {
                            title: $t('IM12.specifications.physical.interface.title'),
                            contentList: [$t('IM12.specifications.physical.interface.value')]
                        }
                    ]
                },
                {
                    key: $t('IM12.specifications.environment.title'),
                    tableData: [
                        {
                            title: $t('IM12.specifications.environment.workingTemp.title'),
                            contentList: [$t('IM12.specifications.environment.workingTemp.value')]
                        },
                        {
                            title: $t('IM12.specifications.environment.storageTemp.title'),
                            contentList: [$t('IM12.specifications.environment.storageTemp.value')]
                        }
                    ]
                },
                {
                    key: $t('IM12.specifications.imu.title'),
                    tableData: [
                        {
                            title: $t('IM12.specifications.imu.range.title'),
                            contentList: [
                                $t('IM12.specifications.imu.range.gyro'),
                                $t('IM12.specifications.imu.range.accelerometer')
                            ]
                        },
                        {
                            title: $t('IM12.specifications.imu.bias.title'),
                            contentList: [
                                $t('IM12.specifications.imu.bias.gyro1'),
                                $t('IM12.specifications.imu.bias.gyro2'),
                                $t('IM12.specifications.imu.bias.accelerometer')
                            ]
                        },
                        {
                            title: $t('IM12.specifications.imu.arw.title'),
                            contentList: [
                                $t('IM12.specifications.imu.arw.gyro1'),
                                $t('IM12.specifications.imu.arw.gyro2'),
                                $t('IM12.specifications.imu.arw.accelerometer')
                            ]
                        },
                        {
                            title: $t('IM12.specifications.imu.vrw.title'),
                            contentList: [
                                $t('IM12.specifications.imu.vrw.gyro'),
                                $t('IM12.specifications.imu.vrw.accelerometer')
                            ]
                        }
                    ]
                }
            ]" />

        </div>
    </div>

    <div class="div-spacer-h80"></div>

    <div class="h-c">
        <div class="v-c" style="justify-content: center;">
            <router-link to="/about/contact" style="text-align: center; width: 100%;">
                <AdapButtomBlack :text="$t('IM12.getMoreInfo')" />
            </router-link>
        </div>
    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">
        <h3 class="text-size-64" style="color: rgb(0, 0, 0);
            font-weight: bold;
            text-align: center;">
            {{ $t('IM12.applications.title') }}
        </h3>


    </div>
    <div class="div-spacer-h80"></div>


    <div class="h-c">

        <div v-show="!isMobile" class="v-c">
            <ScrollingCards2 :items="ScrollingCards2_items" />
        </div>

        <div v-show="isMobile" class="v-c">
            <mScrollingCards2 :items="ScrollingCards2_items" />
        </div>





    </div>


    <div class="div-spacer-h288"></div>





</template>



<style scoped>
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/banner车规产品/imu合集.2.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}




.dfsghtr5 {
    padding: 0px 20px;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
}
.dfsghtr5 a{
    width: fit-content;
}
</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import ScrollingCards2 from '@/components/ScrollingCards2.vue';
import mScrollingCards2 from '@/components/mScrollingCards2.vue';
const ScrollingCards2_items = [
    { image: require('@/assets/images/官网开发素材/inertia/p2.jpg'), title: '隧道内岔路口', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p3.jpg'), title: '高架桥下', desc: '无法精准定位导航错过路口' },
    { image: require('@/assets/images/官网开发素材/inertia/p4.jpg'), title: '地下停车场', desc: '无卫星信号' },
    { image: require('@/assets/images/官网开发素材/inertia/p6.jpg'), title: '城市峡谷', desc: '卫星信号弱，多路劲效应影响' },
    { image: require('@/assets/images/官网开发素材/inertia/p5.jpg'), title: '复杂立体交通', desc: '卫星信号无/弱' }
];
import SpecTable from '@/components/SpecTable.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
import FeatureCard from '@/components/FeatureCard.vue';
import CombinedTitle from '@/components/CombinedTitle.vue';
import AdapButtom from '@/components/AdapButtom.vue';
import AdapButtomBlack from '@/components/AdapButtomBlack.vue';
import PictureCard from '@/components/PictureCard.vue';
</script>
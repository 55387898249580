<template>
    <div class="main">



        <BannerSwiper class="banner-swiper" />

        <div class="div-spacer-h288"></div>

        <div class="h-c" style="text-align: center;">
            <h3 style="color: rgb(0, 0, 0);
              
              
              
           
              
              font-weight: bold;
              position: relative;
              
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              text-wrap: nowrap" class="text-size-96 that-line">
                 {{ $t('HomeView.slogan.line1') }}<br />{{ $t('HomeView.slogan.line2') }}
            </h3>
        </div>
        <div class="div-spacer-h288"></div>

        <div class="h-c">
            <div class="v-c rev">

                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-items: left;gap: var(--gap-16);">

                    <div style="color: rgb(0, 0, 0);
                  
                  
                  font-weight: bold;
                  
                  
                  " class="text-size-56 titleline">
                         {{ $t('HomeView.automotive.title') }}
                    </div>
                    <div style="color: rgba(89, 89, 89,1);
                  
                  
                  font-weight: bold;
                  max-width: 475px;
                  
                  text-align: left;" class="text-size-16">
                         {{ $t('HomeView.automotive.description') }}
                    </div>
                    <div>
                        <a v-show="!isMobile" href="/product/automotive">
                            <AdapButtomBold text='了解详情' />
                        </a>

                    </div>

                </div>

                <PictureCard style="max-width: 621px;height: 351px;"
                    :src="require('@/assets/images/官网开发素材/首页/隧道2b.jpg')" />




            </div>

        </div>
        <div class="div-spacer-h80"></div>
        <div class="h-c">
            <div class="v-c" style="gap: 16px;">
                <div class="g-c-4-2-1 disktop-card">
                    <newSummaryCard :number="1" :subtitle="$t('HomeView.automotive.advantages.first.subtitle')" 
                        :title="$t('HomeView.automotive.advantages.first.title')" 
                        :strength="$t('HomeView.automotive.advantages.first.strength')"
                        :description="$t('HomeView.automotive.advantages.first.description')" />
                    <newSummaryCard :number="2" :subtitle="$t('HomeView.automotive.advantages.second.subtitle')" 
                        :title="$t('HomeView.automotive.advantages.second.title')" 
                        :strength="$t('HomeView.automotive.advantages.second.strength')"
                        :description="$t('HomeView.automotive.advantages.second.description')" />
                    <newSummaryCard :number="3" :subtitle="$t('HomeView.automotive.advantages.third.subtitle')" 
                        :title="$t('HomeView.automotive.advantages.third.title')" 
                        :strength="$t('HomeView.automotive.advantages.third.strength')"
                        :description="$t('HomeView.automotive.advantages.third.description')" />
                    <newSummaryCard :number="4" :subtitle="$t('HomeView.automotive.advantages.fourth.subtitle')" 
                        :title="$t('HomeView.automotive.advantages.fourth.title')" 
                        :strength="$t('HomeView.automotive.advantages.fourth.strength')"
                        :description="$t('HomeView.automotive.advantages.fourth.description')" />

                </div>


            </div>
            <div v-show="isMobile" class="v-c">
                <div class="div-spacer-h16"></div>
                <a href="/product/automotive">
                    <AdapButtomBold text='了解详情' />
                </a>
            </div>
        </div>

        <div class="div-spacer-h288"></div>


        <div class="h-c">
            <div class="v-c rev">

                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-items: left;gap: var(--gap-16);width: fit-content;">

                    <div class="text-size-56 titleline" style="color: rgb(0, 0, 0);
                  
                  
                  font-weight: bold;">
                         {{ $t('HomeView.industry.title') }}
                    </div>
                    <div class="text-size-16" style="color: rgba(89, 89, 89,1);
                  
                  max-width: 475px;
                  font-weight: bold;
                  text-align: left;">
                         {{ $t('HomeView.industry.description') }}
                    </div>
                    <div>
                        <a v-show="!isMobile" href="/product/industry">
                            <AdapButtomBold text='了解详情' />
                        </a>
                    </div>

                </div>


                <div style="width: fit-content;display: flex;justify-content: flex-end;flex-direction: column;">

                    <img :src="require('@/assets/images/官网开发素材/首页/行业4拼图.jpg')"
                        style="max-width: fit-content; height: 351px;" />

                </div>



            </div>

        </div>
        <div class="div-spacer-h80"></div>
        <div class="h-c">
            <div class="v-c" style="gap: 16px;">

                <div class="g-c-4-2-1 disktop-card">
                    <newSummaryCard :number="1" :subtitle="$t('HomeView.industry.advantages.first.subtitle')" 
                        :title="$t('HomeView.industry.advantages.first.title')" 
                        :strength="$t('HomeView.industry.advantages.first.strength')"
                        :description="$t('HomeView.industry.advantages.first.description')" />
                    <newSummaryCard :number="2" :subtitle="$t('HomeView.industry.advantages.second.subtitle')" 
                        :title="$t('HomeView.industry.advantages.second.title')" 
                        :strength="$t('HomeView.industry.advantages.second.strength')"
                        :description="$t('HomeView.industry.advantages.second.description')" />
                    <newSummaryCard :number="3" :subtitle="$t('HomeView.industry.advantages.third.subtitle')" 
                        :title="$t('HomeView.industry.advantages.third.title')" 
                        :strength="$t('HomeView.industry.advantages.third.strength')"
                        :description="$t('HomeView.industry.advantages.third.description')" />
                    <newSummaryCard :number="4" :subtitle="$t('HomeView.industry.advantages.fourth.subtitle')" 
                        :title="$t('HomeView.industry.advantages.fourth.title')" 
                        :strength="$t('HomeView.industry.advantages.fourth.strength')"
                        :description="$t('HomeView.industry.advantages.fourth.description')" />

                </div>



            </div>
            <div v-show="isMobile" class="v-c">
                <div class="div-spacer-h16"></div>
                <a href="/product/industry">
                    <AdapButtomBold text='了解详情' />
                </a>
            </div>
        </div>
        <div class="div-spacer-h288"></div>

        <div class="h-c">
            <div class="v-c rev">

                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-items: left;gap: var(--gap-16);">

                    <div class="text-size-56 titleline" style="color: rgb(0, 0, 0);
                  
                  
                  font-weight: bold;">
                         {{ $t('HomeView.solutions.title') }}
                    </div>
                    <div class="text-size-16" style="color: rgba(89, 89, 89,1);
                  
                  
                  
                  font-weight: bold;

                  max-width: 475px;
                  text-align: left;">
                         {{ $t('HomeView.solutions.description') }}
                    </div>
                    <div>
                        <a v-show="!isMobile" href="/solution">
                            <AdapButtomBold text='了解详情' />
                        </a>
                    </div>

                </div>



                <PictureCard style="max-width: 621px;height: 351px;"
                    :src="require('@/assets/images/官网开发素材/行业解决方案/港口/港口.jpg')" />




            </div>

        </div>
        <div class="div-spacer-h80"></div>

        <div class="h-c">
            <div class="v-c" style="gap: 16px;">

                <div class="g-c-4-2-2 disktop-card">
                    <newSummaryCard3 :number="1" :title="$t('HomeView.solutions.scenarios.car.title')"
                        :description="$t('HomeView.solutions.scenarios.car.description')" />
                    <newSummaryCard3 :number="2" :title="$t('HomeView.solutions.scenarios.surveying.title')"
                        :description="$t('HomeView.solutions.scenarios.surveying.description')" />

                    <newSummaryCard3 :number="3" :title="$t('HomeView.solutions.scenarios.robot.title')"
                        :description="$t('HomeView.solutions.scenarios.robot.description')" />
                    <newSummaryCard3 :number="4" :title="$t('HomeView.solutions.scenarios.agriculture.title')"
                        :description="$t('HomeView.solutions.scenarios.agriculture.description')" />
                    <newSummaryCard3 :number="5" :title="$t('HomeView.solutions.scenarios.lowSpeedVehicle.title')"
                        :description="$t('HomeView.solutions.scenarios.lowSpeedVehicle.description')" />
                    <newSummaryCard3 :number="6" :title="$t('HomeView.solutions.scenarios.engineering.title')"
                        :description="$t('HomeView.solutions.scenarios.engineering.description')" />
                    <newSummaryCard3 :number="7" :title="$t('HomeView.solutions.scenarios.uav.title')"
                        :description="$t('HomeView.solutions.scenarios.uav.description')" />
                    <newSummaryCard3 :number="8" :title="$t('HomeView.solutions.scenarios.port.title')"
                        :description="$t('HomeView.solutions.scenarios.port.description')" />
                </div>
            </div>
            <div v-show="isMobile" class="v-c">
                <div class="div-spacer-h16"></div>
                <a href="/solution">
                    <AdapButtomBold text='了解详情' />
                </a>
            </div>

        </div>



        <div class="div-spacer-h288"></div>













        <div class="h-c">
            <div class="v-c rev">

                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-items: left;gap: var(--gap-16);">

                    <div class="text-size-56 titleline" style="color: rgb(0, 0, 0);
                  
                  
                  font-weight: bold;">
                         {{ $t('HomeView.delivery.title') }}
                    </div>
                    <div class="text-size-16" style="color: rgba(89, 89, 89,1);
                  
                  
                  font-weight: bold;
                  
                  max-width: 475px;
                  text-align: left;">
                         {{ $t('HomeView.delivery.description') }}
                    </div>
                    <div>
                        <a v-show="!isMobile" href="/deliver">
                            <AdapButtomBold text='了解详情' />
                        </a>
                    </div>

                </div>



                <PictureCard style="max-width: 621px;height: 351px;"
                    :src="require('@/assets/images/官网开发素材/banner/量产交付.jpg')" />




            </div>

        </div>
        <div class="div-spacer-h80"></div>
        <div class="h-c">
            <div class="v-c">

                <div class="g-c-3-1-1 disktop-card" style="gap: 16px;">
                    <newSummaryCard2 :number="1" :title="$t('HomeView.delivery.features.production.title')"
                        :description="$t('HomeView.delivery.features.production.description')" />

                    <newSummaryCard2 :number="2" :title="$t('HomeView.delivery.features.manufacturing.title')"
                        :description="$t('HomeView.delivery.features.manufacturing.description')" />

                    <newSummaryCard2 :number="3" :title="$t('HomeView.delivery.features.testing.title')"
                        :description="$t('HomeView.delivery.features.testing.description')" />

                </div>





            </div>
            <div class="v-c">
                <div class="div-spacer-h16"></div>
                <a v-show="isMobile" href="/deliver">
                    <AdapButtomBold text='了解详情' />
                </a>
            </div>
        </div>
        <div class="div-spacer-h288"></div>










    </div>
</template>



<style scoped>
div {
    width: 100%;
}

.titleline {
    text-align: left;
}


a {
    width: fit-content;
}


.that-line:before {
    content: '';
    display: block;
    width: 90px;
    border-bottom: solid 4px #000;
    margin-right: 50px;
}

.that-line:after {
    content: '';
    display: block;
    width: 90px;
    border-bottom: solid 4px #000;
    margin-left: 50px;
}


.disktop-card {

    padding-top: 8px;
    padding-bottom: 8px;

}


@media (max-width: 768px) {
    .disktop-card {

        padding-top: 0px;
        padding-bottom: 0px;

    }

    .titleline {
        text-align: center;
    }

    .PictureCard {
        min-width: 100% !important;
    }

    /* 圆角针对性调整 */
    img {
        object-fit: contain;
        /* height: fit-content!important; */
        height: min-content !important;
    }


    /* 那两条线 */
    .that-line:before {
        content: '';
        display: block;
        width: 45px;
        border-bottom: solid 1px #000;
        margin-right: 25px;
    }

    .that-line:after {
        content: '';
        display: block;
        width: 45px;
        border-bottom: solid 1px #000;
        margin-left: 25px;
    }




}
</style>




<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import newSummaryCard3 from '@/components/newSummaryCard3.vue';
import newSummaryCard2 from '@/components/newSummaryCard2.vue';
import newSummaryCard from '@/components/newSummaryCard.vue';
import BannerSwiper from '@/components/BannerSwiper.vue';
import AdapButtomBold from '@/components/AdapButtomBold.vue';
import PictureCard from '@/components/PictureCard.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()

</script>

/* 2产品服务-卫惯系列/车规/模组 */

<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">
            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 588px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255); text-align: left;">
                    {{ $t('AutomotiveIndex.title1') }}
                    <br>{{ $t('AutomotiveIndex.title2') }}
                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-16" style="color: rgb(217, 217, 217); text-align: left;">
                    {{ $t('AutomotiveIndex.description') }}
                </p>


            </div>






        </div>

    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">

        <div class="v-c">



            <div v-show="isMobile">
                <VideoPlayer />
                <div class="div-spacer-h16"></div>
                <div class="div-spacer-h16"></div>
            </div>

            <div v-show="!isMobile" style="display: flex; flex-direction: column; align-items: flex-start">
                <h3 class="text-size-48" style="text-wrap: nowrap;">
                    {{ $t('AutomotiveIndex.productTitle') }}
                </h3>
                <h3 class="text-size-48" style="text-wrap: nowrap; color: rgb(255, 97, 0);">
                    {{ $t('AutomotiveIndex.scenarioTitle') }}
                </h3>
                <ul style="list-style-position: inside;">
                    <li>{{ $t('AutomotiveIndex.scenario1') }}</li>
                    <li>{{ $t('AutomotiveIndex.scenario2') }}</li>
                    <li>{{ $t('AutomotiveIndex.scenario3') }}</li>
                    <li>{{ $t('AutomotiveIndex.scenario4') }}</li>
                    <li>{{ $t('AutomotiveIndex.scenario5') }}</li>
                </ul>

            </div>

            <div v-show="isMobile" style="display: flex; flex-direction: column; align-items: flex-start">
                <h3 class="text-size-48" style="text-wrap: nowrap;">
                    {{ $t('AutomotiveIndex.productTitle') }}
                </h3>
                <h3 class="text-size-48" style="text-wrap: nowrap; color: rgb(255, 97, 0);">
                    {{ $t('AutomotiveIndex.scenarioTitle') }}
                </h3>
                <p class="text-size-16">{{ $t('AutomotiveIndex.mobileScenarios') }}</p>


            </div>
            <div v-show="!isMobile" style="max-width: 621px;height: 321px;">
                <VideoPlayer />
            </div>



        </div>

    </div>

    <div class="div-spacer-h288"></div>

    <div class="h-c">

        <GroupNameCard 
            :title="$t('AutomotiveIndex.group1.title')" 
            :describe="$t('AutomotiveIndex.group1.describe')" 
        />

    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c">




        <div class="v-c">
            <div class="g-c-2-1-1" style="justify-items: center;justify-content: center;gap: 16px;">
                <router-link to="/product/automotive/ib11">
                    <ProductCard 
                        :str1="$t('AutomotiveIndex.products.ib11.title')"
                        :str2="$t('AutomotiveIndex.products.ib11.desc')"
                        :src="require('@/assets/images/官网开发素材/车规产品/imubox蓝色背景.jpg')" 
                    />
                </router-link>

                <router-link to="/product/automotive/p21s">
                    <ProductCard 
                        :str1="$t('AutomotiveIndex.products.p21s.title')"
                        :str2="$t('AutomotiveIndex.products.p21s.desc')"
                        :src="require('@/assets/images/官网开发素材/车规产品/P21S蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" 
                    />
                </router-link>

                <router-link to="/product/automotive/p22d">
                    <ProductCard 
                        :str1="$t('AutomotiveIndex.products.p22d.title')"
                        :str2="$t('AutomotiveIndex.products.p22d.desc')"
                        :src="require('@/assets/images/官网开发素材/车规产品/P22D蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" 
                    />
                </router-link>

                <router-link to="/product/automotive/cxp-fusion">
                    <ProductCard 
                        :str1="$t('AutomotiveIndex.products.cxpFusion.title')"
                        :str2="$t('AutomotiveIndex.products.cxpFusion.desc')"
                        :src="require('@/assets/images/官网开发素材/车规产品/cxpfusion2蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" 
                    />
                </router-link>

            </div>

        </div>


    </div>
    <div class="div-spacer-h288"></div>
    <div class="h-c">

        <GroupNameCard 
            :title="$t('AutomotiveIndex.group2.title')" 
            :describe="$t('AutomotiveIndex.group2.describe')" 
        />

    </div>

    <div class="div-spacer-h48"></div>



    <div class="h-c">

        <div class="v-c">
            <div class="g-c-3-1-1" style="justify-items: center;justify-content: center;gap: 16px;">
                <router-link to="/product/automotive/inm11">
                    <ProductCard 
                        :str1="$t('AutomotiveIndex.products.inm11.title')"
                        :str2="$t('AutomotiveIndex.products.inm11.desc')"
                        :src="require('@/assets/images/官网开发素材/车规产品/INM11蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" 
                    />
                </router-link>

                <router-link to="/product/automotive/imu/im11">
                    <ProductCard 
                        :str1="$t('AutomotiveIndex.products.imu.title')"
                        :str2="$t('AutomotiveIndex.products.imu.desc')"
                        :src="require('@/assets/images/官网开发素材/车规产品/imu合集蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" 
                    />
                </router-link>

                <router-link to="/product/automotive/gnm11">
                    <ProductCard 
                        :str1="$t('AutomotiveIndex.products.gnm11.title')"
                        :str2="$t('AutomotiveIndex.products.gnm11.desc')"
                        :src="require('@/assets/images/官网开发素材/车规产品/GNM11蓝色背景.jpg')"
                        style="min-height: 228px;min-width: 304px;" 
                    />
                </router-link>
            </div>

        </div>
    </div>


    <div class="div-spacer-h288"></div>

</template>



<style scoped>

a{

    width: 100% !important;
    height: 100%;

}
.banner {

    background-image: url("@/assets/images/官网开发素材/banner/隧道1b.jpg");

    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



a{
    width: fit-content;
}


</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果
import VideoPlayer from '@/components/VideoPlayer.vue';
import GroupNameCard from '@/components/GroupNameCard.vue';
import ProductCard from '@/components/ProductCard.vue';
import { useScreenWidth } from '@/js/useScreenWidth'
const isMobile = useScreenWidth()
</script>
<!-- 解决方案-测绘 -->

<template>


    <div class="h-c banner" style="justify-content: flex-end;">

        <div class="v-c">

            <div
                style="display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: var(--space-banner-bottom-125);max-width: 970px;">
                <h1 class="text-size-72" style="color: rgb(255, 255, 255); font-weight: bold; text-align: left;">
                    {{ $t('SolutionView8.title') }}
                </h1>

                <div class="div-spacer-h16"></div>
                <p class="text-size-24" style="color: rgb(255, 255, 255); text-align: left;">
                    {{ $t('SolutionView8.description') }}
                </p>


            </div>





        </div>

    </div>
<div class="div-spacer-h288"></div>

    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/测绘/测绘3.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 275px;">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); font-weight: bold; text-align: left;">
                    {{ $t('SolutionView8.surveyingTitle') }}
                </h3>
                <p class="text-size-16" style="color: rgb(0, 0, 0); text-align: left;">
                    {{ $t('SolutionView8.surveyingDesc') }}
                </p>
            </div>

        </div>
    </div>
    <div class="div-spacer-h288"></div>


    <div class="h-c" >
        <div class="v-c">

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: center;">
                    {{ $t('SolutionView8.painPointTitle') }}
                </h3>

                <p>
                    {{ $t('SolutionView8.painPoint1Title') }}<br>
                    {{ $t('SolutionView8.painPoint1Desc') }}
                </p>
                <p>
                    {{ $t('SolutionView8.painPoint2Title') }}<br>
                    {{ $t('SolutionView8.painPoint2Desc') }}
                </p>
                <p>
                    {{ $t('SolutionView8.painPoint3Title') }}<br>
                    {{ $t('SolutionView8.painPoint3Desc') }}
                </p>
                <p>
                    {{ $t('SolutionView8.painPoint4Title') }}<br>
                    {{ $t('SolutionView8.painPoint4Desc') }}
                </p>


            </div>

        </div>

    </div>


    <div class="div-spacer-h288"></div>




    <div class="h-c" >
        <div class="v-c">
            <PictureCard :src="require('@/assets/images/官网开发素材/行业解决方案/测绘/测绘2.jpg')"
                style="max-width: 621px;height: 351px;" />

            <div style="display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0px;
            max-width: 459px;
            max-height: 227px;

            ">
                <h3 class="text-size-48" style="color: rgb(0, 0, 0); text-align: left;">
                    {{ $t('SolutionView8.advantageTitle') }}
                </h3>







                <ul class="text-size-16" style="color: rgba(89, 89, 89,1); text-align: left; list-style-position: inside;">
                    <li>{{ $t('SolutionView8.advantage1') }}</li>
                    <li>{{ $t('SolutionView8.advantage2') }}</li>
                    <li>{{ $t('SolutionView8.advantage3') }}</li>
                    <li>{{ $t('SolutionView8.advantage4') }}</li>
                    <li>{{ $t('SolutionView8.advantage5') }}</li>
                    <li>{{ $t('SolutionView8.advantage6') }}</li>
                </ul>
            </div>

        </div>
    </div>

    <div class="div-spacer-h288"></div>


    <div class="h-c" >
        <h3 class="text-size-64" style="color: rgb(0, 0, 0); text-align: center;">
            {{ $t('SolutionView8.relatedProducts') }}
        </h3>

    </div>

    <div class="div-spacer-h48"></div>

    <div class="h-c">


        <RelatedScrollingCards3 :products="RelatedScrollingCards3Products" :visibleImages="2" />

    </div>
    <div class="div-spacer-h288"></div>









</template>



<style scoped>


.banner {

    
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("@/assets/images/官网开发素材/banner/行业解决方案banner/测绘1.jpg");
    z-index: -1;
    
    background-size: cover;
    /* 使背景图片覆盖整个 div */
    background-position: center;
    /* 使背景图片居中 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */


}



</style>



<script setup>
// 添加h-c进入时的动画效果
import { useScrollAnimation } from '@/js/useScrollAnimation.js'
useScrollAnimation()
// 添加h-c进入时的动画效果

import PictureCard from '@/components/PictureCard.vue';
import RelatedScrollingCards3 from '@/components/RelatedScrollingCards3.vue';
const RelatedScrollingCards3Products = [
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/cxgy-110 IMU/CXGY-110 IMU.1.jpg"),
    title: "CXGY-110 IMU",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy110"
  },
  {
    imageUrl: require("@/assets/images/官网开发素材/工规产品/CXGY-150 INS-BOX双天线/CXGY-150 INS-BOX双天线pbox.3.jpg"),
    title: "CXGY-150 INS-BOX",
    description: "CXGY-100 系列",
    link: "/product/industry/cxgy150"
  }
]



</script>
<template>
    <div class="newSummaryCard">
        <div 
            class="numbered-card"
            :class="{ 'is-hovered': isHovered }"
            :style="cardStyle"
            @mouseenter="isHovered = true"
            @mouseleave="isHovered = false"
        >
            <div class="number-column">
                <span class="text-size-64">{{ number }}</span>
            </div>
            <div class="content-wrapper">
                <div class="content">
                    <div class="subtitle">
                        <span class="text-size-20">{{ subtitle }}</span>
                    </div>
                    <h2 class="title text-size-24">{{ title }}</h2>
                </div>
                <div class="strength">
                    <span class="text-size-56">{{ strength }}</span>
                </div>
                <div class="description">
                    <p class="text-size-14">{{ description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';

defineProps({
    number: {
        type: [String, Number],
        default: 1
    },
    subtitle: {
        type: String,
        default: '优势一'
    },
    title: {
        type: String,
        default: '自研算法'
    },
    strength: {
        type: String,
        default: '强'
    },
    description: {
        type: String,
        default: '自研融合算法体系，拥有六十余年军工融合，成熟可靠的技术成果积累沉淀'
    }
});

const isHovered = ref(false);

const cardStyle = computed(() => ({
    transform: `scale(${isHovered.value ? 1.01 : 1})`,
    transition: 'all 500ms ease-in-out'
}));
</script>

<style scoped>
.newSummaryCard {
    
    overflow: visible;
    
    text-align: left !important;
    height: 100%;
    
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    overflow: visible;
    
}
.numbered-card {
    height: 100%;

    width: 100%;

    backdrop-filter: blur(30px);
    background: rgb(245, 245, 247);
    
    border-radius: var(--cx-radius);
    padding: 16px;

    display: grid;
    grid-template-columns: auto 1fr;
    /* gap: 1rem; */
    position: relative;
    z-index: 1;
    cursor: pointer;
    transition: all 500ms ease-in-out;
}

/* 悬浮状态样式 */
.numbered-card.is-hovered {
    background-color: white;
    box-shadow: 0px 5px 27px 2px rgba(0, 0, 0, 0.05);
}

.number-column {
    padding-right: 16px;
}

.number-column span {
    font-weight: 400;
    color: #374151;
    transition: all 500ms ease-in-out;
}

.content-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    /* gap: 0.5rem; */
}

.content {
    grid-column: 1;
}

.subtitle {
    margin-bottom: 0.5rem;
}

.subtitle span {
    
    color: #6B7280;
    transition: all 500ms ease-in-out;
}

.title {
    
    font-weight: 700;
    color: #1F2937;
    transition: all 500ms ease-in-out;
}

.strength {
    grid-column: 2;
}

.strength span {
    
    font-weight: 700;
    color: #1F2937;
    transition: all 500ms ease-in-out;
}

.description {
    grid-column: 1 / -1;
    padding-top: 16px;
}

.description p {
    
    color: #6B7280;
    line-height: 1.625;
    transition: all 500ms ease-in-out;
}

/* 调整悬浮状态下的颜色 */
.numbered-card.is-hovered .number-column span,
.numbered-card.is-hovered .title,
.numbered-card.is-hovered .strength span {
    color: #111827;
}

.numbered-card.is-hovered .subtitle span,
.numbered-card.is-hovered .description p {
    color: #2c323a;
}



</style>